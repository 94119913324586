.app {
    inset: 0;
    width: 100%;
    display: flex;
    position: fixed;
    // overflow-y: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: var(--transition);
    background-color: var(--compose-primary-background);

    /* ---- ---- estructura ---- ---- */

    .menu {
        top: 0px;
        left: 0px;
        z-index: 10;
        height: 100vh;
        display: flex;
        position: fixed;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: var(--compose-menu-width);
        transition: var(--compose-transition);
        border-right: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        .wrapper {
            gap: 40px;
            margin: 0px;
            display: flex;
            width: calc(100% - 30px);
            height: calc(100% - 40px);
            align-items: center;
            flex-direction: column !important;
            justify-content: space-between;

            .flex {
                gap: 40px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;

                .logo {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--compose-color-white);

                    svg {
                        color: initial;
                    }
                }

                .nav-menu {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .nav-list {
                        gap: 4px;
                        width: 100%;
                        margin: 0px;
                        padding: 0px;
                        display: flex;
                        list-style: none;
                        position: relative;
                        align-items: center;
                        flex-direction: column;

                        .nav-item {
                            width: 50px;
                            height: 50px;
                            display: flex;
                            cursor: pointer;
                            position: relative;
                            border-radius: 12px;
                            align-items: center;
                            justify-content: center;
                            transition: var(--compose-transition);

                            .nav-link {
                                width: 100%;
                                height: 100%;
                                border: none;
                                display: flex;
                                cursor: pointer;
                                font-size: 14px;
                                background: none;
                                position: relative;
                                align-items: center;
                                flex-direction: row;
                                letter-spacing: .4px;
                                text-decoration: none;
                                justify-content: center;
                                color: var(--compose-primary-text);
                                transition: var(--compose-transition);

                                .nav-title {
                                    opacity: 0;
                                    padding: 2px 4px;
                                    visibility: hidden;
                                    position: absolute;
                                    border-radius: 4px;
                                    min-width: max-content;
                                    left: calc(100% + 25px);
                                    transition: var(--compose-transition);
                                    font: normal 14px var(--font-display);
                                    border: 1px solid var(--compose-border-color);
                                    background-color: var(--compose-secondary-background-blur);
                                }
                            }

                            &:hover {
                                background-color: #2626264d !important;

                                .nav-link {
                                    color: var(--compose-primary-text) !important;

                                    .nav-title {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }

                            &.show,
                            &.active {

                                .nav-link {
                                    color: var(--compose-primary-text);
                                }
                            }
                        }
                    }
                }

                &:nth-child(1) {
                    justify-content: flex-start;
                }

                &:nth-child(2) {
                    justify-content: center;
                }

                &:nth-child(3) {
                    justify-content: flex-end;
                }
            }
        }
    }

    .menu-alt {
        left: 0px;
        z-index: 5;
        bottom: 0px;
        padding: 6px;
        display: flex;
        min-width: 200px;
        border-radius: 16px;
        position: absolute;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        transition: var(--compose-transition);
        border: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);
        visibility: hidden;
        opacity: 0;

        hr {
            width: 100%;
            height: 1px;
            border: none;
            user-select: none;
            pointer-events: none;
            background-color: var(--compose-border-color);
        }

        .link-alt {
            width: 100%;
            height: 50px;
            padding: 0px;
            border: none;
            display: flex;
            color: inherit;
            cursor: pointer;
            border-radius: 12px;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            background-color: transparent;
            transition: var(--compose-transition);

            .wrapper-alt {
                gap: 15px;
                width: calc(100% - 40px);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .icon-alt {
                    width: 24px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        font-size: 14px;
                        user-select: none;
                        pointer-events: none;
                    }
                }

                .text-alt {
                    width: 100%;
                    display: flex;
                    text-align: left;
                    align-items: center;
                    padding-right: 34px;
                    letter-spacing: .4px;
                    min-width: max-content;
                    justify-content: flex-start;
                    font: normal 14px var(--font-display);
                }
            }

            &.alert {
                color: red;
            }

            &:hover {
                background-color: var(--compose-hovered-background);
            }
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .menu-mobile {
        gap: 8px;
        bottom: 0;
        z-index: 10;
        width: 100%;
        display: none;
        position: fixed;
        padding: 10px 0px;
        align-items: center;
        justify-content: center;
        border-top: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        .menu-mobile-wrapper {
            width: calc(100% - 48px);
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }

        .menu-mobile-item {
            gap: 4px;
            width: 50px;
            height: 50px;
            display: flex;
            overflow: hidden;
            border-radius: 12px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border: 1px solid transparent;
            font: normal 12.5px var(--font-display);

            a {
                width: 100%;
                height: 100%;
                display: flex;
                color: inherit;
                padding: 0 16px;
                align-items: center;
                text-decoration: none;
                justify-content: center;

                img {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;
                    justify-content: center;
                }
            }

            &:hover {
                border-color: var(--compose-border-color);

                img {
                    transform: scale(1.06) rotate(-16deg);
                }
            }
        }
    }

    .main {
        top: 0px;
        right: 0px;
        z-index: 1;
        bottom: 0px;
        width: calc(var(--compose-main-width) - var(--compose-menu-width));
        min-height: 100vh;
        display: flex;
        position: fixed;
        overflow-y: scroll;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        transition: var(--compose-transition);
        background-color: var(--compose-primary-background);

        .container {
            gap: 20px;
        }
    }

    .create {
        inset: 0;
        gap: 20px;
        opacity: 0;
        z-index: 10;
        display: flex;
        position: fixed;
        visibility: hidden;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(50px);
        transition: var(--compose-transition);
        background-color: rgba(0, 0, 0, 0.5);

        .close {
            top: 16px;
            left: 16px;
            width: 44px;
            height: 44px;
            position: fixed;
            border-radius: 50%;
            border: 1px solid var(--compose-border-color);
            background-color: var(--compose-primary-background);

            &:hover {
                transform: scale(1.08);
            }
        }

        .create-form {
            width: 400px;
            width: 620px;
            // padding: 16px;
            overflow: hidden;
            border-radius: 16px;
            border: 1px solid var(--compose-border-color);
            background-color: var(--compose-secondary-background);

            .compose-caption {
                width: calc(100% - 32px);
                display: flex;
                padding: 16px;
                cursor: default;
                max-height: 100px;
                align-items: center;
                justify-content: flex-start;

                .user-box {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .user-box-picture {
                        width: 36px;
                        height: 36px;
                        display: flex;
                        overflow: hidden;
                        align-items: center;
                        border-radius: 12px;
                        justify-content: center;
                        border: 1px solid var(--compose-border-color);

                        img,
                        .user-picture {
                            width: 100%;
                            display: flex;
                            aspect-ratio: 1;
                            user-select: none;
                            object-fit: cover;
                            align-items: center;
                            pointer-events: none;
                            object-position: center;
                            justify-content: center;
                            color: var(--compose-secondary-text);
                            background-color: var(--compose-secondary-background);
                        }
                    }

                    .user-box-data {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;

                        .user-box-name {
                            gap: 2px;
                            display: flex;
                            align-items: center;
                            letter-spacing: .4px;
                            justify-content: flex-start;
                            font: normal 14px var(--font-display);

                            .verified-badge {
                                width: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .user-box-username {
                            letter-spacing: .2px;
                            color: var(--compose-secondary-text);
                            font: normal 12px var(--font-display);
                        }
                    }
                }
            }

            form {
                display: flex;
                position: relative;
                flex-direction: column;

                .wrapper-field {
                    gap: 8px;
                    width: 100%;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    .title {
                        padding: 0;
                        width: calc(100% - 32px);
                        margin: 0 16px;
                        border: none;
                        cursor: text;
                        background: none;
                        appearance: none;
                        border-radius: 0;
                        font: bold 13.5px / 2.5 var(--font-display);

                        &:focus-visible {
                            outline: none;
                        }
                    }

                    .text {
                        padding: 0;
                        width: calc(100% - 32px);
                        margin: 0 16px;
                        min-height: 34px;
                        border: none;
                        resize: none;
                        background: none;
                        appearance: none;
                        letter-spacing: .4px;
                        scrollbar-width: none;
                        font: 11.5px / 1.5 var(--font-display);

                        &:focus-visible {
                            outline: none;
                        }
                    }

                    .counter {
                        min-width: 50px;
                        height: 34px;
                        display: flex;
                        margin-left: -8px;
                        margin-right: 16px;
                        align-items: center;
                        letter-spacing: .4px;
                        justify-content: flex-end;
                        color: var(--compose-secondary-text);
                        transition: var(--compose-transition);
                        font: normal 11.5px / 1.5 var(--font-display);
                        background-color: var(--compose-secondary-background);
                    }
                }

                .wrapper-photos {
                    width: 100%;
                    display: flex;
                    overflow: hidden;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid var(--compose-border-color);
                    border-bottom: 1px solid var(--compose-border-color);

                    .counter {
                        top: 16px;
                        z-index: 2;
                        opacity: 0;
                        right: 16px;
                        user-select: none;
                        position: absolute;
                        visibility: hidden;
                        pointer-events: none;
                        transition: var(--compose-transition);
                        font: normal 11.5px / 1.5 var(--font-mono);
                    }

                    .icon {
                        z-index: 2;
                        opacity: 0;
                        right: 8px;
                        bottom: 8px;
                        position: absolute;
                        visibility: hidden;
                    }

                    &:hover {

                        .icon {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    .photo-item {
                        z-index: 1;
                        width: 100%;
                        display: flex;
                        max-height: 620px;
                        position: relative;
                        align-items: center;
                        justify-content: center;

                        img {
                            z-index: 2;
                            max-width: 100%;
                            max-height: 620px;
                            object-fit: cover;
                            user-select: none;
                            pointer-events: none;
                            object-position: center;
                            font: normal 11.5px var(--font-display);
                        }

                        .photo-back {
                            inset: 0;
                            z-index: 1;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            position: absolute;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 100%;
                                height: 100%;
                                filter: blur(100px);
                            }
                        }
                    }

                    .prev-button,
                    .next-button {
                        opacity: 0;
                        visibility: hidden;
                        background: rgba(0, 0, 0, 0.5);
                        width: 32px;
                        height: 32px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        padding: 10px;
                        cursor: pointer;
                        position: absolute;
                        border-radius: 100%;
                        aspect-ratio: 1;
                        transform: translateY(-50%);
                        transition: var(--compose-transition);
                        z-index: 2;
                        top: 50%;

                        &:hover {
                            transform: translateY(-50%) scale(1.07);
                        }
                    }

                    .prev-button {
                        left: 16px;
                    }

                    .next-button {
                        right: 16px;
                    }

                    &:hover {

                        .counter {
                            opacity: 1;
                            visibility: visible;
                        }

                        .wrapper-photos {
                            .counter {
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .prev-button,
                        .next-button {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                .wrapper-survey {
                    gap: 8px;
                    width: calc(100% - 32px);
                    display: flex;
                    padding: 0 16px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .item {
                        width: calc(100% - 28px - 2px);
                        height: 34px;
                        display: flex;
                        padding: 0 14px;
                        cursor: pointer;
                        align-items: center;
                        border-radius: 12px;
                        justify-content: space-between;
                        border: 1px solid var(--compose-border-color);

                        .text {
                            font: normal 12px var(--font-display);
                        }

                        .counter {
                            font: normal 12px var(--font-mono);
                        }

                        &.active {
                            background-color: rgba(62, 165, 255, 0.18);
                        }
                    }
                }

                .error {
                    color: red;
                    padding: 0 16px;
                    padding-top: 10px;
                    letter-spacing: .4px;
                    font: normal 11.5px var(--font-display);
                }

                input,
                select {
                    width: 100%;
                    height: 36px;
                    cursor: pointer;
                    appearance: none;
                    background: none;
                    padding: 0px 14px;
                    border-radius: 12px;
                    letter-spacing: .4px;
                    background-color: #121212;
                    font: normal 1rem var(--font-display);
                    border: 1px solid var(--compose-border-color);

                    &:focus-visible {
                        outline: none;
                    }
                }

                .selected-images {
                    gap: 4px;
                    width: 100%;
                    cursor: grab;
                    height: 280px;
                    display: flex;
                    // margin: 12px 0px;
                    overflow: hidden;
                    border-radius: 12px;
                    justify-content: flex-start;
                    transition: var(--compose-transition);

                    .image-preview {
                        overflow: hidden;
                        user-select: none;
                        position: relative;
                        border-radius: 12px;
                        min-width: max-content;
                        border: 1px solid var(--compose-border-color);
                        background-color: var(--compose-primary-background);

                        img {
                            z-index: 1;
                            height: 100%;
                            object-fit: cover;
                            user-select: none;
                            pointer-events: none;
                        }

                        video {
                            height: 100%;
                        }

                        button {
                            top: 8px;
                            right: 8px;
                            z-index: 2;
                            opacity: 0;
                            width: 30px;
                            height: 30px;
                            cursor: pointer;
                            position: absolute;
                            visibility: hidden;
                            border-radius: 12px;
                            transition: var(--compose-transition);
                            border: 1px solid var(--compose-border-color);
                            background-color: var(--compose-secondary-background);
                        }

                        &:hover {

                            button {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                    }

                    &.grabbing {
                        cursor: grabbing;
                    }
                }

                .column {
                    gap: 8px;
                    padding: 16px 16px 0;
                    width: calc(100% - 32px);
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .video {
                    width: 100% !important;
                    border-top: 1px solid var(--compose-border-color);
                    border-bottom: 1px solid var(--compose-border-color);
                }

                .form-action {
                    gap: 8px;
                    width: calc(100% - 32px);
                    display: flex;
                    padding: 16px;
                    align-items: center;
                    justify-content: space-between;

                    .flex {
                        gap: 8px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        button[type="submit"] {
                            height: 34px;
                            cursor: pointer;
                            padding: 0px 16px;
                            border-radius: 12px;
                            letter-spacing: .4px;
                            font: normal 12px var(--font-display);
                            border: 1px solid var(--compose-border-color);
                            background-color: var(--compose-border-color);
                        }

                        &:first-child {
                            justify-content: flex-start;
                        }

                        &:last-child {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .language {
        inset: 0;
        z-index: 8;
        opacity: 0;
        display: flex;
        position: fixed;
        visibility: hidden;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(50px);
        transition: var(--compose-transition);
        background-color: rgba(0, 0, 0, 0.5);

        .close {
            left: calc(80px + 16px);
            top: 16px;
            width: 44px;
            height: 44px;
            position: fixed;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            border-color: var(--compose-border-color) !important;
            background-color: var(--compose-secondary-background) !important;

            &:hover {
                transform: scale(1.1);
            }
        }

        .grid {
            gap: 10px;
            display: grid;
            padding: 16px;
            border-radius: 16px;
            max-width: var(--compose-wrapper);
            // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            grid-template-columns: repeat(4, 1fr);
            border: 1px solid var(--compose-border-color);
            background-color: var(--compose-primary-background);

            .label {
                grid-column: span 4;
                font: bold 16px var(--font-display);
            }

            button {
                grid-column: span 1;
                margin: 0px;
                height: 30px;
                display: flex;
                cursor: pointer;
                padding: 0px 10px;
                align-items: center;
                border-radius: 8px;
                letter-spacing: .4px;
                min-width: max-content;
                justify-content: center;
                background-color: transparent;
                font: normal 12px var(--font-display);
                border: 1px solid var(--compose-color-white);

                &:disabled {
                    cursor: no-drop;
                    border-color: var(--compose-border-color);
                }
            }
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .glass {
        inset: 0px;
        opacity: 0;
        width: 100%;
        z-index: 8;
        height: 100vh;
        border: none;
        display: flex;
        position: fixed;
        visibility: hidden;
        align-items: center;
        justify-content: center;
        background-color: #00000080;
        transition: var(--compose-transition);
        background-color: var(--compose-primary-background-blur);

        div,
        img {
            pointer-events: none;
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    /* ---- ---- clases globales ---- ---- */

    .form {
        gap: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .block {
            width: 100%;
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            label {
                margin-left: 6px;
                margin-bottom: 8px;
                padding: 0 !important;
                font: 500 13.5px var(--font-display) !important;
            }

            .wrapper-field {
                width: 100%;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                input,
                select,
                textarea {
                    width: 100%;
                }

                #username {
                    padding-left: 22px !important;
                }

                .at {
                    left: 12px;
                    color: #a6a4a1;
                    user-select: none;
                    position: absolute;
                    pointer-events: none;
                    font: normal 11.5px var(--font-display);
                }

                svg {
                    z-index: 2;
                    right: 12px;
                    left: inherit;
                    user-select: none;
                    position: absolute;
                    pointer-events: none;
                }
            }

            input,
            select {
                width: calc(100% - 30px);
                height: 36px;
                appearance: none;
                border-radius: 12px;
                padding: 0px 12px !important;
                font: normal 11.5px var(--font-display) !important;
                border: 1px solid var(--compose-border-color);

                &:disabled {
                    cursor: not-allowed;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            textarea {
                resize: none;
                width: calc(100% - 30px);
                min-height: 36px;
                padding: 12px 12px;
                border-radius: 12px;
                letter-spacing: .4px;
                font: normal 11.5px var(--font-display);
                border: 1px solid var(--compose-border-color);

                &:focus-visible {
                    outline: none;
                }
            }

            .counter {
                right: 0px;
                bottom: 0px;
                position: absolute;
            }

            button {
                width: 100%;
                height: 36px;
                padding: 0px 24px;
                color: var(--compose-color-white);
                background-color: var(--compose-color-primary);
            }

            .counter {
                right: 12px;
                bottom: 12px;
                user-select: none;
                position: absolute;
                pointer-events: none;
                font: normal 11.5px var(--font-mono);
            }

            &.hidden {
                opacity: 0;
                display: none;
                visibility: hidden;
            }

            &.row {
                gap: 12px;
                flex-direction: row;
            }
        }

        .wrapper-field {
            gap: 8px;
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            .title {
                padding: 0;
                width: calc(100% - 32px);
                margin: 0 16px;
                border: none;
                cursor: text;
                background: none;
                appearance: none;
                border-radius: 0;
                font: bold 13.5px / 2.5 var(--font-display);

                &:focus-visible {
                    outline: none;
                }
            }

            .text {
                padding: 0;
                width: calc(100% - 32px);
                margin: 0 16px;
                min-height: 34px;
                border: none;
                resize: none;
                overflow: hidden;
                background: none;
                appearance: none;
                letter-spacing: .4px;
                scrollbar-width: none;
                font: normal 11.5px var(--font-display);

                &:focus-visible {
                    outline: none;
                }
            }

            .counter {
                min-width: 50px;
                height: 34px;
                display: flex;
                margin-left: -8px;
                margin-right: 16px;
                align-items: center;
                letter-spacing: .4px;
                justify-content: flex-end;
                color: var(--compose-secondary-text);
                transition: var(--compose-transition);
                font: normal 11.5px var(--font-mono) !important;
                background-color: var(--compose-secondary-background) !important;
            }
        }
    }

    hr {
        width: 100%;
        height: 1px;
        margin: 0px;
        border: none;
        user-select: none;
        pointer-events: none;
        transition: var(--compose-transition);
        background-color: var(--compose-border-color);
    }

    a {
        text-decoration: none;
        color: var(--compose-color-primary);

        &:hover {
            text-decoration: underline;
        }
    }

    // className // global // 

    .logo {
        font-size: 15px;
        transition: var(--compose-transition);

        &:hover {
            transform: scale(1.1);
        }
    }

    .no-link {
        color: inherit;
        text-decoration: none;
    }

    .wrapper {
        display: flex;
        align-items: center;
    }

    .lang {
        width: 30px;
        height: 30px;
        display: flex;
        margin-right: 15px;
        align-items: center;
        text-transform: uppercase;
        justify-content: flex-start;
        font: bold 20px var(--font-urbanist);
    }

    // components // pages // 

    .main {
        // margin-bottom: 100px;

        .container {
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .feed {
            // gap: 20px;
            width: 100%;
            display: flex;
            // padding: 40px 0;
            align-items: center;
            padding-bottom: 120px;
            flex-direction: column;
            justify-content: flex-start;

            .feed-header {
                top: 8px;
                gap: 8px;
                z-index: 2;
                width: 100%;
                display: flex;
                position: sticky;
                // margin-top: -20px;
                align-items: center;
                justify-content: center;
                transition: var(--compose-transition);

                // &.scale:hover {
                //     transform: scale(1.06) rotate(-6deg);
                // }

                // &:nth-child(even) {
                //     &.scale:hover {
                //         transform: scale(1.06) rotate(0deg);
                //     }
                // }
            }

            .feed-body {
                gap: 20px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
            }

            .error {
                z-index: 1;
                user-select: none;
                letter-spacing: .4px;
                font: normal 11.5px var(--font-display);
            }
        }

        .aside {
            width: 352px; // 360px, 356px, 352px
            display: flex;
            min-width: 352px;
            max-width: 352px;
            align-items: flex-end;
            flex-direction: column;
            padding: 20px 0px 75px;
            justify-content: flex-start;
        }
    }

    .watch {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        // background-color: steelblue;

        .watch-grid {
            gap: 30px;
            display: grid;
            padding: 30px 0;
            // width: 1400px;
            width: calc(100% - 60px);
            // min-height: 100vh;
            // background-color: rosybrown;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .watch-page {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .watch-page-content {
            width: calc(100% - 60px);
            display: flex;
            // background-color: cadetblue;

            .watch-page-content-ppl {
                width: 1280px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // background-color: firebrick;

                video {
                    width: 1280px;
                    height: 720px;
                    margin-top: 30px;
                    border-radius: 12px;
                }
            }
        }

        .watch-page-comments {
            width: calc(100% - 60px);
            height: 400vh;
            // background-color: royalblue;
        }
    }

    .email {
        right: 0;
        bottom: 0;
        width: calc(100% - 80px);
        display: flex;
        position: fixed;
        align-items: flex-end;
        justify-content: center;
        height: calc(100vh - 80px);
        background-color: var(--background-color);

        .email-box {
            width: calc(100% - 280px);
            height: calc(100vh - 80px - 30px);
            display: flex;
            // background-color: var();
            border: 1px solid var(--border-color);

            .header-chat {
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                // background-color: firebrick;
                border-bottom: 1px solid var(--border-color);
            }

            .chats-list {
                width: 400px;
                height: 100%;
                visibility: hidden;
                visibility: visible;
                border-right: 1px solid var(--border-color);
                // background-color: rebeccapurple;
            }

            .chat-box {
                width: calc(100% - 400px);
                height: 100%;
                // background-color: steelblue;
            }
        }
    }

    /* ---- ---- componentes ---- ---- */

    .post {
        z-index: 1;
        width: 100%;
        width: calc(100% - 2px);
        // width: 620px;
        // width: 850px;
        display: flex;
        max-width: 620px;
        position: relative;
        border-radius: 16px;
        flex-direction: column;
        border: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        a {
            color: inherit;
            text-decoration: none;
        }

        .post-menu {
            right: 0;
            top: 100%;
            top: 16px;
            z-index: 8;
            opacity: 0;
            padding: 6px;
            display: flex;
            overflow: hidden;
            position: absolute;
            visibility: hidden;
            border-radius: 16px;
            flex-direction: column;
            min-width: fit-content;
            transition: var(--compose-transition);
            border: 1px solid var(--compose-border-color);
            background-color: var(--compose-secondary-background);

            hr {
                width: 100%;
                height: 1px;
                border: none;
                margin: 6px 0px;
                user-select: none;
                pointer-events: none;
                background-color: var(--compose-border-color);
            }

            .wrapper {
                flex-direction: row !important;
            }

            .post-menu-item {
                width: 100%;
                height: 38px;
                border: none;
                display: flex;
                cursor: pointer;
                align-items: center;
                border-radius: 12px;
                padding-right: 16px;
                justify-content: center;
                transition: var(--compose-transition);
                background-color: var(--compose-secondary-background);

                .wrapper {
                    gap: 14px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    margin: 0px 6px;
                    align-items: center;
                    justify-content: flex-start;

                    .icon {
                        display: flex;
                        font-size: 12px;
                        user-select: none;
                        align-items: center;
                        pointer-events: none;
                        justify-content: flex-start;
                    }

                    .text {
                        user-select: none;
                        letter-spacing: .2px;
                        min-width: max-content;
                        font: normal 12px var(--font-display);
                    }
                }

                &:hover {
                    background-color: var(--compose-hovered-background);
                }
            }

            &.show {
                opacity: 1;
                visibility: visible;
            }
        }

        .post-head {
            gap: 8px;
            z-index: 4;
            display: flex;
            margin: 0 16px;
            padding: 16px 0;
            position: relative;
            align-items: center;
            justify-content: space-between;
            background-color: var(--compose-secondary-background);

            .icon-button {
                z-index: 1;
                width: 36px;
                height: 36px;
                display: flex;
                min-width: 36px;
                cursor: pointer;
                align-items: center;
                border-radius: 12px;
                justify-content: center;
                background-color: transparent;
                border: 1px solid transparent;
                transition: var(--compose-transition);

                &:hover {
                    background-color: rgba(30, 30, 30);
                    border-color: var(--compose-border-color);
                }
            }
        }

        .post-content {
            z-index: 2;
            width: 100%;
            display: flex;
            overflow: hidden;
            position: relative;
            flex-direction: column;
            background-color: var(--compose-secondary-background);

            .title {
                margin: 0 16px;
                color: var(--compose-primary-text);
                font: bold 13.5px / 2 var(--font-display);
            }

            .text {
                margin: 0px 16px;
                letter-spacing: .4px;
                white-space: pre-wrap;
                color: var(--compose-primary-text);
                font: 300 11.5px / 1.75 var(--font-display);

                a {
                    text-decoration: none;
                    color: var(--compose-color-primary);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .space {
                width: 100%;
                height: 12px;
                user-select: none;
                pointer-events: none;
            }

            .wrapper-photos {
                width: 100%;
                display: flex;
                overflow: hidden;
                position: relative;
                align-items: center;
                justify-content: center;
                border-top: 1px solid var(--compose-border-color);
                border-bottom: 1px solid var(--compose-border-color);

                .counter {
                    top: 16px;
                    z-index: 2;
                    opacity: 0;
                    right: 16px;
                    user-select: none;
                    position: absolute;
                    visibility: hidden;
                    pointer-events: none;
                    font: normal 12px var(--font-mono);
                    transition: var(--compose-transition);
                }

                .photo-item {
                    z-index: 1;
                    width: 100%;
                    display: flex;
                    max-height: 620px;
                    position: relative;
                    align-items: center;
                    justify-content: center;

                    img {
                        z-index: 2;
                        max-width: 100%;
                        max-height: 620px;
                        object-fit: cover;
                        user-select: none;
                        pointer-events: none;
                        object-position: center;
                        font: normal 11.5px var(--font-display);
                    }

                    .photo-back {
                        inset: 0;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        position: absolute;
                        align-items: center;
                        justify-content: center;
                        background-color: #121212;

                        img {
                            width: 100%;
                            height: 100%;
                            filter: blur(100px);
                        }
                    }
                }

                .prev-button,
                .next-button {
                    opacity: 0;
                    visibility: hidden;
                    background: rgba(0, 0, 0, 0.5);
                    width: 32px;
                    height: 32px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    padding: 10px;
                    cursor: pointer;
                    position: absolute;
                    border-radius: 100%;
                    aspect-ratio: 1;
                    transform: translateY(-50%);
                    transition: var(--compose-transition);
                    z-index: 2;
                    top: 50%;

                    &:hover {
                        transform: translateY(-50%) scale(1.07);
                    }
                }

                .prev-button {
                    left: 16px;
                }

                .next-button {
                    right: 16px;
                }

                &:hover {

                    .counter {
                        opacity: 1;
                        visibility: visible;
                    }

                    .wrapper-photos {
                        .counter {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    .prev-button,
                    .next-button {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .wrapper-video {
                width: 100%;
                display: flex;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                border-top: 1px solid var(--compose-border-color);
                border-bottom: 1px solid var(--compose-border-color);

                .video {
                    cursor: pointer;
                    width: 100% !important;
                    height: 350px !important;
                    background-color: #121212;
                }
            }

            .wrapper-audio {
                width: 100%;
                display: flex;
                aspect-ratio: 1;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                border-top: 1px solid var(--compose-border-color);
                border-bottom: 1px solid var(--compose-border-color);

                .cover {
                    inset: 0;
                    z-index: 1;
                    width: 100%;
                    object-fit: cover;
                    user-select: none;
                    position: absolute;
                    pointer-events: none;
                    object-position: center;
                }

                .back {
                    inset: 0;
                    z-index: 2;
                    opacity: 0;
                    position: absolute;
                    visibility: hidden;
                    transition: var(--compose-transition);
                    background-color: rgba(0, 0, 0, 0.5);
                }

                .time {
                    top: 16px;
                    z-index: 3;
                    opacity: 0;
                    right: 16px;
                    user-select: none;
                    position: absolute;
                    visibility: hidden;
                    pointer-events: none;
                    font: normal 12px var(--font-mono);
                    transition: var(--compose-transition);
                }

                .play {
                    z-index: 3;
                    opacity: 0;
                    width: 44px;
                    height: 44px;
                    border: none;
                    display: flex;
                    cursor: pointer;
                    visibility: hidden;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    transition: var(--compose-transition);
                }

                .bar {
                    bottom: 0;
                    z-index: 3;
                    opacity: 0;
                    width: 100%;
                    display: flex;
                    padding: 26px 0px;
                    position: absolute;
                    visibility: hidden;
                    align-items: center;
                    justify-content: center;
                    transition: var(--compose-transition);
                    $accent-color: var(--compose-color-white);
                    background: linear-gradient(rgba(0, 0, 0, 0.010), rgba(0, 0, 0, 0.4));

                    // .progress {
                    //     appearance: none;
                    //     -webkit-appearance: none;
                    //     width: calc(100% - 32px);
                    //     height: 3px;
                    //     border-radius: 5px;
                    //     outline: none;
                    //     cursor: pointer;
                    //     transition: opacity 0.2s;
                    //     accent-color: $accent-color;
                    //     background: linear-gradient(to right,
                    //             $accent-color 0%,
                    //             $accent-color calc(var(--value, 0) * 1%),
                    //             rgba(255, 255, 255, 0.5) calc(var(--value, 0) * 1%),
                    //             rgba(255, 255, 255, 0.5) 100%);

                    //     &::-webkit-slider-thumb {
                    //         appearance: none;
                    //         width: 10px;
                    //         height: 10px;
                    //         cursor: pointer;
                    //         border-radius: 50%;
                    //         background: $accent-color;
                    //         transition: var(--compose-transition);

                    //         &:hover {
                    //             transform: scale(1.2);
                    //         }
                    //     }

                    //     &::-moz-range-track {
                    //         height: 3px;
                    //         border-radius: 5px;
                    //         background: transparent;
                    //     }

                    //     &::-moz-range-thumb {
                    //         width: 10px;
                    //         height: 10px;
                    //         cursor: pointer;
                    //         border-radius: 50%;
                    //         background: $accent-color;
                    //     }

                    //     &::-ms-track {
                    //         width: 100%;
                    //         height: 3px;
                    //         background: transparent;
                    //         border-color: transparent;
                    //         color: transparent;
                    //     }

                    //     &::-ms-thumb {
                    //         width: 10px;
                    //         height: 10px;
                    //         cursor: pointer;
                    //         border-radius: 50%;
                    //         background: $accent-color;
                    //     }
                    // }
                }

                &:hover {

                    .back,
                    .time,
                    .play,
                    .bar {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .repost {
                margin: 0 16px;
                padding: 16px 0;
                overflow: hidden;
                border-radius: 12px;
                border: 1px solid var(--compose-border-color);

                .copyrigth {
                    gap: 4px;
                    display: flex;
                    margin: 0 16px;
                    padding: 16px 0 0;
                    align-items: center;
                    justify-content: flex-start;
                    font: normal 12.5px var(--font-display);

                    .of {
                        color: var(--compose-secondary-text);
                    }

                    a,
                    .link {
                        gap: 2px;
                        display: flex;
                        cursor: pointer;
                        font-weight: bold;
                        align-items: center;
                        justify-content: flex-start;
                        color: var(--compose-primary-text);
                    }
                }
            }

            .wrapper-link {
                padding: 0 16px;
                width: calc(100% - 32px - 2px);

                .card-link {
                    // width: calc(620px - 32px - 2px);
                    width: 100%;
                    width: calc(100% - 2px);
                    display: flex;
                    cursor: pointer;
                    overflow: hidden;
                    border-radius: 8px;
                    align-items: center;
                    flex-direction: column;
                    border: 1px solid var(--compose-border-color);
                    background-color: #121212;
                    text-decoration: none;
                    color: inherit;

                    .card-link-image {
                        width: 100%;
                        // height: 240px;
                        // height: 260px;
                        // min-height: 280px;
                        max-height: calc(480px - 1px);
                        display: flex;
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        background-color: #121212;
                        border-bottom: 1px solid var(--compose-border-color);
                        // background-color: var(--compose-color-white);

                        img {
                            width: 100%;
                            border: none;
                            // aspect-ratio: 1;
                            object-fit: cover;
                            user-select: none;
                            pointer-events: none;
                            object-position: center;
                            box-sizing: border-box;
                        }
                    }

                    .card-link-content {
                        gap: 8px;
                        width: calc(100% - 32px);
                        display: flex;
                        padding: 16px;
                        flex-direction: column;

                        .link {
                            color: var(--compose-secondary-text);
                            font: 300 12px var(--font-display);
                        }

                        .title {
                            margin: 0;
                            color: var(--compose-primary-text);
                            font: bold 15px var(--font-display) !important;
                        }

                        .description {
                            overflow: hidden;
                            display: -webkit-box;
                            letter-spacing: .4px;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            font: 300 12px var(--font-display);
                        }
                    }
                }

                .card {
                    width: 100%;
                    // height: calc(106px - 2px);
                    display: flex;
                    overflow: hidden;
                    border-radius: 8px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--compose-border-color);
                    background-color: #121212;
                    text-decoration: none;
                    color: inherit;

                    .card-image {
                        width: 120px;
                        height: 120px;
                        display: flex;
                        aspect-ratio: 1;
                        align-items: center;
                        justify-content: center;
                        background-color: #121212;

                        img {
                            width: 100%;
                            height: 100%;
                            border: none;
                            aspect-ratio: 1;
                            object-fit: cover;
                            object-position: center;
                            box-sizing: border-box;
                            pointer-events: none;
                            user-select: none;
                        }
                    }

                    .card-content {
                        gap: 8px;
                        width: calc(100% - 32px);
                        padding: 14px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;

                        .link {
                            letter-spacing: .4px;
                            color: var(--compose-secondary-text);
                            font: 300 12px var(--font-display);
                        }

                        .title {
                            margin: 0;
                            letter-spacing: .4px;
                            font: bold 15px var(--font-display) !important;
                        }

                        .description {
                            overflow: hidden;
                            display: -webkit-box;
                            letter-spacing: .4px;
                            line-clamp: 3;
                            -webkit-line-clamp: 3;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            font: 300 12px var(--font-display);
                        }
                    }
                }
            }
        }

        .post-action {
            z-index: 3;
            display: flex;
            margin: 0 16px;
            padding: 16px 0;
            padding: 8px 0;
            padding: 12px 0;
            align-items: center;
            background-color: var(--compose-secondary-background);

            .flex {
                gap: 6px;
                width: 100%;
                display: flex;
                align-items: center;

                &:first-child {
                    justify-content: flex-start;
                }

                &:last-child {
                    justify-content: flex-end;

                    .reaction-area {
                        justify-content: flex-end;
                    }
                }
            }
        }

        .post-form {
            z-index: 1;
            display: none;
            margin: 0 16px;
            padding: 16px 0;
            flex-direction: column;
            transition: var(--compose-transition);
            border-top: 1px solid var(--compose-border-color);
            background-color: var(--compose-secondary-background);

            form {
                gap: 8px;
                width: 100%;
                display: flex;
                align-items: center;

                a {
                    height: 34px;
                    display: flex;
                    color: inherit;
                    min-width: 34px;
                    overflow: hidden;
                    border-radius: 12px;
                    align-items: center;
                    text-decoration: none;
                    justify-content: center;
                    color: var(--compose-secondary-text);
                    transition: var(--compose-transition);
                    border: 1px solid var(--compose-border-color);
                    background-color: var(--compose-secondary-background);

                    img {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 1;
                        object-fit: cover;
                        user-select: none;
                        pointer-events: none;
                        object-position: center;
                    }

                    &:hover {
                        transform: scale(1.06) rotate(-16deg);
                    }
                }

                .wrapper-field {
                    width: 100%;
                    display: flex;
                    overflow: hidden;
                    position: relative;
                    align-items: center;

                    input {
                        z-index: 1;
                        padding-right: 40px;
                    }

                    .counter {
                        z-index: 2;
                        right: 14px;
                        user-select: none;
                        position: absolute;
                        pointer-events: none;
                        letter-spacing: .4px;
                        color: var(--compose-secondary-text);
                        font: normal 11.5px var(--font-display);
                    }
                }

                input {
                    width: 100%;
                    border: none;
                    height: 34px;
                    padding: 0 14px;
                    background: none;
                    border-radius: 12px;
                    letter-spacing: .4px;
                    height: calc(36px - 4px);
                    background-color: #121212;
                    font: normal 11.5px var(--font-display);
                    border: 1px solid var(--compose-border-color);

                    &:focus-visible {
                        outline: none;
                    }
                }

                button {
                    height: 34px;
                    border: none;
                    cursor: pointer;
                    user-select: none;
                    padding: 0px 14px;
                    border-radius: 12px;
                    letter-spacing: .4px;
                    transition: var(--compose-transition);
                    font: normal 11.5px var(--font-display);
                    background-color: var(--compose-border-color);
                    border: 1px solid var(--compose-border-color);
                }
            }
        }

        .post-comment-input {
            gap: 8px;
            display: flex;
            margin: 0 16px;
            padding: 16px 0;
            align-items: center;
            justify-content: center;
            border-top: 1px solid var(--compose-border-color);

            .wrapper-field {
                width: 100%;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                input {
                    width: 100%;
                    height: 34px;
                    border: none;
                    padding: 0 14px;
                    padding-right: 40px;
                    border-radius: 10px;
                    letter-spacing: .4px;
                    background-color: #121212;
                    font: normal 11.5px var(--font-display);
                    border: 1px solid var(--compose-border-color);

                    &:focus-visible {
                        outline: none;
                    }
                }

                .counter {
                    right: 14px;
                    user-select: none;
                    position: absolute;
                    pointer-events: none;
                    color: var(--compose-secondary-text);
                    font: normal 11.5px var(--font-mono);
                }
            }
        }

        .post-comments {
            gap: 10px;
            z-index: 1;
            display: flex;
            margin: 0 16px;
            padding: 16px 0;
            letter-spacing: .4px;
            flex-direction: column;
            font: normal 11.5px var(--font-display);
            border-top: 1px solid var(--compose-border-color);

            .comment {
                gap: 10px;
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .comment-wrapper {
                    gap: 10px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                }

                .comment-user {
                    width: 36px;
                    display: flex;
                    min-width: 36px;
                    align-items: flex-start;
                    justify-content: center;
                }

                .comment-text {
                    gap: 8px;
                    width: 100%;
                    display: flex;
                    overflow: hidden;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    color: var(--compose-secondary-text);

                    .info {
                        gap: 2px;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: flex-start;

                        .name {
                            gap: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            color: var(--compose-primary-text);
                            font: bold 13.5px var(--font-display);

                            .user-link {
                                gap: 2px;
                                display: flex;
                                align-items: center;
                            }

                            .author {
                                gap: 1px;
                                display: flex;
                                align-items: center;
                                letter-spacing: .4px;
                                color: var(--compose-color-blue);
                                font: normal 11.5px var(--font-display);
                            }

                            .pin {
                                display: flex;
                                cursor: default;
                                color: #cdcdcd;
                                user-select: none;
                                align-items: center;
                                letter-spacing: .4px;
                                transition: var(--compose-transition);
                                font: normal 10.5px var(--font-display);
                                background-color: #f1f2f030;
                                border-radius: 4px;
                                padding: 2px 1px;
                                padding-right: 4px;
                            }
                        }

                        .username {
                            gap: 3px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            color: var(--compose-secondary-text);
                            font: normal 12px var(--font-display);
                        }
                    }

                    .text {
                        width: 100%;
                        height: 100%;
                        letter-spacing: .4px;
                        white-space: pre-wrap;
                        color: var(--compose-primary-text);
                        font: normal 11.5px / 1.75 var(--font-display);
                    }

                    .action {
                        gap: 8px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        .loved {
                            width: 36px;
                            height: 36px;
                            display: none;
                            position: relative;
                            align-items: center;
                            justify-content: center;

                            img {
                                z-index: 1;
                                position: absolute;
                                border-radius: 8px;
                            }

                            svg {
                                right: 0;
                                bottom: 0;
                                z-index: 2;
                                position: absolute;
                                color: var(--compose-color-red);
                            }
                        }
                    }
                }

                .comment-action {
                    height: 100%;
                    display: flex;
                    position: relative;
                    align-items: center;
                    flex-direction: column;
                    justify-content: flex-start;

                    .icon {
                        border-color: transparent;
                    }

                    .post-menu {
                        top: 0;
                    }
                }
            }

            .comments-empty {
                gap: 12px;
                width: 100%;
                display: flex;
                min-height: 180px;
                align-items: center;
                padding-bottom: 20px;
                flex-direction: column;
                justify-content: center;
                pointer-events: none;
                user-select: none;

                .title {
                    font: bold 20px var(--font-display);
                }

                .subtitle {
                    font: normal 13px var(--font-display);
                }
            }
        }

        .post-posted {
            display: flex;
            margin: 0 16px;
            padding: 12px 0;
            align-items: center;
            letter-spacing: .4px;
            justify-content: space-between;
            font: normal 11.5px var(--font-mono);
            color: var(--compose-secondary-text);
            border-bottom: 1px solid var(--compose-border-color);
        }

        .error {
            gap: 20px;
            display: flex;
            margin: 0 16px;
            padding: 16px 0;
            align-items: center;
            letter-spacing: .4px;
            flex-direction: column;
            justify-content: center;
            font: normal 13px var(--font-display);
        }
    }

    .article {
        padding: 10px 15px;
        border-radius: 16px;
        border: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        h1 {
            margin: 0px;
            font: bold 18px / 2 var(--font-display);
        }

        small {
            color: var(--color-inactive);
            font: normal 10px / 1.5 var(--font-display);
        }

        p {
            display: flex;
            text-wrap: balance;
            margin-bottom: 0px;
            // text-align: justify;
            font: normal 12px / 2 var(--font-display);
        }

        .article-action {
            gap: 8px;
            width: 100%;
            display: flex;
            margin-top: 10px;
            padding-top: 10px;
            align-items: center;
            border-top: 1px solid var(--compose-border-color);

            form {
                gap: 6px;
                width: 50%;
                height: 100%;
                display: none;
                align-items: center;
                justify-content: flex-start;

                input {
                    width: 100%;
                    height: 30px;
                    padding: 0px 12px;
                    border-radius: 8px;
                    letter-spacing: .4px;
                    font: normal 12px var(--font-display);
                    border: 1px solid var(--compose-border-color);
                    background-color: var(--compose-primary-background);
                }

                button[type="submit"] {
                    height: 30px;
                    border: none;
                    cursor: pointer;
                    border-radius: 8px;
                    font: normal 12px var(--font-display);
                    background-color: var(--primary-color);
                }
            }

            .button.icon {
                width: 32px;
                height: 32px;
                font-size: 12px;
                border-radius: 8px;

                &:hover {
                    background-color: var(--compose-primary-background);
                }
            }
        }
    }

    .sidebar {
        width: 100%;
        display: flex;
        overflow: hidden;
        border-radius: 16px;
        flex-direction: column;
        border: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        .sidebar-title {
            display: flex;
            margin: 0 16px;
            padding: 16px 0;
            align-items: center;
            justify-content: flex-start;
            font: bold 18px var(--font-display);
        }

        .sidebar-content {
            display: flex;
            margin: 0 16px;

            .moments {
                gap: 2px;
                width: 100%;
                display: flex;
                overflow: hidden;
                overflow-x: auto;
                border-radius: 12px;
                flex-direction: row;
                scrollbar-width: none;

                .moment-item {
                    height: 200px;
                    // height: 250px;
                    cursor: pointer;
                    min-width: 120px;
                    // min-width: 140px;
                    overflow: hidden;
                    // aspect-ratio: 1/2;
                    border-radius: 12px;
                    background-color: var(--compose-primary-background);
                }
            }

            .trends {
                gap: 4px;
                width: 100%;
                display: flex;
                flex-direction: column;

                .trend-card {
                    gap: 12px;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    overflow: hidden;
                    align-items: center;
                    justify-content: space-between;

                    .trend-card-link {
                        width: 100%;
                        color: inherit;
                        text-decoration: none;
                    }

                    .trend-card-details {
                        gap: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        svg {
                            pointer-events: none;
                            color: var(--compose-color-white);
                        }

                        #tag {
                            margin-left: 4px;
                            border-radius: 4px;
                            position: relative;
                            transform: rotate(45deg);
                            color: var(--color-white);
                            background-color: var(--primary-color);

                            &::before {
                                top: 0;
                                left: 0;
                                width: 24px;
                                height: 24px;
                                content: "tag";
                                position: absolute;
                                border-radius: 4px;
                                transform: rotate(-45deg);
                                background-color: var(--primary-color);
                            }
                        }

                        .trend-card-wrapper {
                            gap: 4px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;

                            .trend-card-title {
                                color: var(--compose-primary-text);
                                font: bold 16px var(--font-display);
                            }

                            .trend-card-subtitle {
                                color: var(--compose-secondary-text);
                                font: normal 12px var(--font-display);
                            }
                        }
                    }
                }
            }

            .popular {
                gap: 8px;
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .about {
                gap: 12px;
                width: 100%;
                display: flex;
                margin-bottom: 16px;
                flex-direction: column;

                .about-wrap {
                    gap: 4px;
                    display: flex;
                    flex-wrap: wrap;

                    .about-link {
                        letter-spacing: .2px;
                        text-decoration: none;
                        color: var(--compose-secondary-text);
                        font: normal 12px var(--font-display);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .about-copy {
                    gap: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        letter-spacing: .2px;
                        color: var(--compose-secondary-text);
                        font: normal 12.5px var(--font-display);

                        &:last-child {
                            cursor: help;
                        }
                    }
                }
            }
        }
    }

    .avatar-box {
        display: flex;
        cursor: pointer;
        overflow: hidden;
        align-items: center;
        border-radius: 12px;
        justify-content: center;
        background-color: #121212;
        transition: var(--compose-transition);
        border: 1px solid var(--compose-border-color);

        .avatar {
            border: none;
            aspect-ratio: 1;
            object-fit: cover;
            user-select: none;
            pointer-events: none;
            object-position: center;
        }

        &:hover {
            transform: scale(1.06) rotate(-16deg);
        }
    }

    .user-card {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-info {
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            a {
                color: inherit;
                text-decoration: none;

                &:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }

            .user-picture {
                width: 36px;
                height: 36px;
                display: flex;
                aspect-ratio: 1;
                overflow: hidden;
                object-fit: cover;
                border-radius: 12px;
                align-items: center;
                pointer-events: none;
                object-position: center;
                justify-content: center;
                color: var(--compose-secondary-text);
                transition: var(--compose-transition);
                background-color: var(--compose-primary-background);
            }

            a:hover {
                .user-picture {
                    transform: scale(1.06) rotate(-16deg);
                }
            }

            .user-data {
                gap: 2px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .user-details {
                    gap: 4px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;

                    .name {
                        width: fit-content;
                        height: 17px;
                        overflow: hidden;
                        max-width: 200px;
                        text-align: left;
                        white-space: nowrap;
                        letter-spacing: .2px;
                        text-overflow: ellipsis;
                        color: var(--compose-primary-text);
                        font: bold 14px var(--font-display);
                    }

                    .verified {
                        width: 14px;
                        height: 14px;
                        display: flex;
                        align-items: center;
                        pointer-events: none;
                        justify-content: center;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .username,
                    .posted {
                        margin: 0;
                        padding: 0;
                        border: none;
                        width: fit-content;
                        letter-spacing: .4px;
                        min-width: max-content;
                        color: var(--compose-secondary-text);
                        font: normal 12px var(--font-display);
                    }
                }
            }
        }
    }

    .watch-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: goldenrod;

        .watch-card-video {
            display: flex;
            position: relative;
            align-items: center;
            // background-color: gainsboro;

            a {
                display: flex;
                border-radius: 12px;
                background-color: rebeccapurple;
            }

            video {
                width: 100%;
                overflow: hidden;
                object-fit: cover;
                aspect-ratio: 16/9;
                border-radius: 12px;
            }
        }

        .watch-card-details {
            // background-color: seagreen;

            .watch-card-details-title {
                margin: 10px 0 14px;
                line-height: 1.5 !important;
                font: bold 14px var(--font-urbanist);
                // background-color: rebeccapurple;
            }

            .watch-card-details-user {
                // padding: 0 12px;
                border-radius: 12px;
                // background-color: var(--color-white);
            }
        }
    }

    .notification {
        width: 100%;
        height: 85px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--compose-border-color);

        .notification-link {
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            color: inherit;
            align-items: center;
            text-decoration: none;
            justify-content: space-between;
        }

        .notification-wrapper {
            gap: 10px;
            width: 100%;
            display: flex;
            padding: 16px 0;
            align-items: center;
            justify-content: flex-start;

            .picture-box {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                .picture {
                    width: 36;
                    height: 36;
                    user-select: none;
                    border-radius: 12px;
                    pointer-events: none;
                    transition: var(--compose-transition);
                }

                &:hover {
                    .picture {
                        transform: scale(1.06) rotate(-16deg);
                    }
                }
            }

            .notification-content {
                gap: 4px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .title {
                    font: normal 14px var(--font-display);
                }

                .time {
                    font: normal 12px var(--font-display);
                }
            }
        }

        .notification-photo {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                width: 50px;
                height: 50px;
                aspect-ratio: 1;
                object-fit: cover;
                user-select: none;
                border-radius: 14px;
                pointer-events: none;
                object-position: center;
                border: 1px solid var(--compose-border-color);
            }
        }

        &:first-child {
            border-top: 1px solid var(--compose-border-color);
        }

        // &:last-child {
        //     border: none;
        // }
    }

    /* ---- ---- páginas ---- ---- */

    .notify {
        background-color: rosybrown;
    }

    table {
        cursor: pointer;
        border-radius: 12px;
        border: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        th,
        td {
            height: 30px;
            padding: 0 12px;
            text-align: left;
            letter-spacing: .4px;
            border-right: 1px solid var(--compose-border-color);

            &:last-child {
                border-right: none;
            }
        }

        th {
            color: var(--compose-primary-text);
            font: bold 12.5px var(--font-mono);
            border-bottom: 1px solid var(--compose-border-color);
        }

        td {
            color: var(--compose-secondary-text);
            font: normal 11.5px var(--font-mono);
            transition: var(--compose-transition);
        }

        tr {
            &:hover {
                td {
                    color: var(--compose-primary-text);
                }
            }
        }
    }

    .popup {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 10;
        display: flex;
        position: absolute;
        visibility: hidden;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: var(--compose-primary-background-blur);
        // background-color: rgba(0, 0, 0, 0.7);
        
        .profile-menu {
            padding: 6px;
            width: calc(620px / 2);
            display: flex;
            overflow: hidden;
            border-radius: 16px;
            flex-direction: column;
            border: 1px solid var(--compose-border-color);
            background-color: var(--compose-secondary-background);

            hr {
                margin: 6px 0px;
            }

            .profile-menu-item {
                width: 100%;
                height: 48px;
                border: none;
                display: flex;
                cursor: pointer;
                align-items: center;
                border-radius: 12px;
                padding-right: 16px;
                justify-content: center;
                transition: var(--compose-transition);
                background-color: var(--compose-secondary-background);

                .wrapper {
                    gap: 14px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    margin: 0px 6px;
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;

                    .icon {
                        display: flex;
                        user-select: none;
                        align-items: center;
                        pointer-events: none;
                        justify-content: center;
                    }

                    .text {
                        user-select: none;
                        letter-spacing: .4px;
                        min-width: max-content;
                        font: normal 14px var(--font-display);
                    }
                }

                &:hover {
                    background-color: var(--compose-hovered-background);
                }

                &:disabled {
                    cursor: no-drop;
                }
            }
        }

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }
}

.app.no-scroll {
    position: fixed;
}

.login-page,
.register-page {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--compose-primary-background);

    .form-box {
        width: 300px;
        padding: 16px;
        margin-top: 200px;
        border-radius: 16px;
        border: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        .form {
            display: flex;
            align-items: center;
            flex-direction: column;

            .form-group {
                gap: 4px;
                width: 100%;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                input,
                select {
                    width: 100%;
                    height: 40px;
                    border: none;
                    letter-spacing: .4px;
                    font: normal 12px var(--font-display);
                    border-bottom: 1px solid var(--compose-border-color);
                    background-color: var(--compose-secondary-background);

                    &:focus-visible {
                        outline: none;
                    }
                }

                select {
                    cursor: pointer;
                    appearance: none;
                }

                button {
                    width: 100%;
                    height: 40px;
                    color: white;
                    cursor: pointer;
                    margin: 20px 0px;
                    border-radius: 12px;
                    letter-spacing: .4px;
                    background-color: black;
                    border: 1px solid black;
                    font: normal 13.5px var(--font-display);
                }

                a {
                    width: 100%;
                    display: flex;
                    margin-bottom: 8px;
                    align-items: center;
                    letter-spacing: .4px;
                    text-decoration: none;
                    justify-content: center;
                    color: rgb(39, 130, 227);
                    font: normal 12px var(--font-display);

                    &:hover {
                        text-decoration: underline;
                    }
                }

                svg {
                    right: 2px;
                    position: absolute;
                    pointer-events: none;
                }

                span {
                    width: 100%;
                    height: 24px;
                    display: flex;
                    user-select: none;
                    color: firebrick;
                    align-items: center;
                    letter-spacing: .2px;
                    font: normal 10.5px / 2 var(--font-display);
                }
            }
        }
    }
}

.comps {
    width: 100%;
    display: flex;
    overflow-y: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    .title-i {
        margin: 40px 0px;
        font: bold 24px var(--font-display);
    }

    .title-ii {
        margin: 18px 0px;
        font: bold 20px var(--font-display);
    }

    hr {
        margin: 0px;
        height: 1px;
        width: 620px;
        border: none;
        user-select: none;
        border-radius: 16px;
        pointer-events: none;
        background-color: var(--compose-border-color);
    }

    .column {
        gap: 14px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 230px;
    }
}

@media screen and (max-width: 1242px) {
    .app {
        .main {
            .container {
                width: calc(100% - 40px);

                .profile {

                    .profile-header {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 64em) {

    // .post {
    //     width: calc(100%) !important;
    // }

    .menu {
        display: none !important;
    }

    .menu-mobile {
        display: flex !important;
    }

    .main {
        width: 100% !important;

        .container {
            width: 100% !important;
        }

        .container {
            margin: 0px 30px;
            // width: 100% !important;
            justify-content: center !important;
            // background-color: orange;

            .feed {
                width: calc(100% - 50px) !important;
                // background-color: greenyellow;
            }

            .aside {
                display: none !important;
            }
        }
    }

}

@media screen and (max-width: 40em) {

    .menu {
        bottom: 0;
        width: 100%;
        top: initial;
        border-right: none;
        margin-bottom: -1px;
        height: calc(50px + 16px + 16px);
        border-top: 1px solid var(--compose-border-color);
        background-color: var(--compose-secondary-background);

        .wrapper {
            gap: 0;
            height: initial;

            .flex {

                .logo {
                    display: none;
                }

                .nav-menu {

                    .nav-list {
                        gap: 8px;
                        flex-direction: row;
                        justify-content: center;

                        .nav-title {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    .nav-menu {
                        display: none;
                    }
                }

            }
        }
    }

    .main {
        .container {

            .aside {
                display: none !important;
            }

            .post {
                max-width: 100%;
                min-width: 100%;

                .post-head {
                    .user-card {

                        .follow-button,
                        .followed-button {
                            max-width: 80px !important;
                            display: none !important;
                        }
                    }
                }
            }
        }

        .feed {
            margin: 0 !important;
        }
    }

    .post {
        .post-head {
            .user-card {

                .follow,
                .followed {
                    display: none !important;
                }
            }
        }
    }
}

@import url(./global.css);
@import url(./pages.css);
@import url(./button.css);
@import url(./components.css);