@charset "UTF-8";
@import url(./global.css);
@import url(./pages.css);
@import url(./button.css);
@import url(./components.css);
.app {
  inset: 0;
  width: 100%;
  display: flex;
  position: fixed;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: var(--transition);
  background-color: var(--compose-primary-background);
  /* ---- ---- estructura ---- ---- */
  /* ---- ---- clases globales ---- ---- */
  /* ---- ---- componentes ---- ---- */
  /* ---- ---- páginas ---- ---- */
}
.app .menu {
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: var(--compose-menu-width);
  transition: var(--compose-transition);
  border-right: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .menu .wrapper {
  gap: 40px;
  margin: 0px;
  display: flex;
  width: calc(100% - 30px);
  height: calc(100% - 40px);
  align-items: center;
  flex-direction: column !important;
  justify-content: space-between;
}
.app .menu .wrapper .flex {
  gap: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .menu .wrapper .flex .logo {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--compose-color-white);
}
.app .menu .wrapper .flex .logo svg {
  color: initial;
}
.app .menu .wrapper .flex .nav-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app .menu .wrapper .flex .nav-menu .nav-list {
  gap: 4px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  list-style: none;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item {
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  transition: var(--compose-transition);
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item .nav-link {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  background: none;
  position: relative;
  align-items: center;
  flex-direction: row;
  letter-spacing: 0.4px;
  text-decoration: none;
  justify-content: center;
  color: var(--compose-primary-text);
  transition: var(--compose-transition);
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item .nav-link .nav-title {
  opacity: 0;
  padding: 2px 4px;
  visibility: hidden;
  position: absolute;
  border-radius: 4px;
  min-width: -moz-max-content;
  min-width: max-content;
  left: calc(100% + 25px);
  transition: var(--compose-transition);
  font: normal 14px var(--font-display);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background-blur);
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item:hover {
  background-color: rgba(38, 38, 38, 0.3019607843) !important;
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item:hover .nav-link {
  color: var(--compose-primary-text) !important;
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item:hover .nav-link .nav-title {
  opacity: 1;
  visibility: visible;
}
.app .menu .wrapper .flex .nav-menu .nav-list .nav-item.show .nav-link, .app .menu .wrapper .flex .nav-menu .nav-list .nav-item.active .nav-link {
  color: var(--compose-primary-text);
}
.app .menu .wrapper .flex:nth-child(1) {
  justify-content: flex-start;
}
.app .menu .wrapper .flex:nth-child(2) {
  justify-content: center;
}
.app .menu .wrapper .flex:nth-child(3) {
  justify-content: flex-end;
}
.app .menu-alt {
  left: 0px;
  z-index: 5;
  bottom: 0px;
  padding: 6px;
  display: flex;
  min-width: 200px;
  border-radius: 16px;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  transition: var(--compose-transition);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
  visibility: hidden;
  opacity: 0;
}
.app .menu-alt hr {
  width: 100%;
  height: 1px;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  background-color: var(--compose-border-color);
}
.app .menu-alt .link-alt {
  width: 100%;
  height: 50px;
  padding: 0px;
  border: none;
  display: flex;
  color: inherit;
  cursor: pointer;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  background-color: transparent;
  transition: var(--compose-transition);
}
.app .menu-alt .link-alt .wrapper-alt {
  gap: 15px;
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.app .menu-alt .link-alt .wrapper-alt .icon-alt {
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .menu-alt .link-alt .wrapper-alt .icon-alt svg {
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.app .menu-alt .link-alt .wrapper-alt .text-alt {
  width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
  padding-right: 34px;
  letter-spacing: 0.4px;
  min-width: -moz-max-content;
  min-width: max-content;
  justify-content: flex-start;
  font: normal 14px var(--font-display);
}
.app .menu-alt .link-alt.alert {
  color: red;
}
.app .menu-alt .link-alt:hover {
  background-color: var(--compose-hovered-background);
}
.app .menu-alt.show {
  opacity: 1;
  visibility: visible;
}
.app .menu-mobile {
  gap: 8px;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: none;
  position: fixed;
  padding: 10px 0px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .menu-mobile .menu-mobile-wrapper {
  width: calc(100% - 48px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.app .menu-mobile .menu-mobile-item {
  gap: 4px;
  width: 50px;
  height: 50px;
  display: flex;
  overflow: hidden;
  border-radius: 12px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  font: normal 12.5px var(--font-display);
}
.app .menu-mobile .menu-mobile-item a {
  width: 100%;
  height: 100%;
  display: flex;
  color: inherit;
  padding: 0 16px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}
.app .menu-mobile .menu-mobile-item a img {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
}
.app .menu-mobile .menu-mobile-item:hover {
  border-color: var(--compose-border-color);
}
.app .menu-mobile .menu-mobile-item:hover img {
  transform: scale(1.06) rotate(-16deg);
}
.app .main {
  top: 0px;
  right: 0px;
  z-index: 1;
  bottom: 0px;
  width: calc(var(--compose-main-width) - var(--compose-menu-width));
  min-height: 100vh;
  display: flex;
  position: fixed;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  transition: var(--compose-transition);
  background-color: var(--compose-primary-background);
}
.app .main .container {
  gap: 20px;
}
.app .create {
  inset: 0;
  gap: 20px;
  opacity: 0;
  z-index: 10;
  display: flex;
  position: fixed;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  transition: var(--compose-transition);
  background-color: rgba(0, 0, 0, 0.5);
}
.app .create .close {
  top: 16px;
  left: 16px;
  width: 44px;
  height: 44px;
  position: fixed;
  border-radius: 50%;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-primary-background);
}
.app .create .close:hover {
  transform: scale(1.08);
}
.app .create .create-form {
  width: 400px;
  width: 620px;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .create .create-form .compose-caption {
  width: calc(100% - 32px);
  display: flex;
  padding: 16px;
  cursor: default;
  max-height: 100px;
  align-items: center;
  justify-content: flex-start;
}
.app .create .create-form .compose-caption .user-box {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.app .create .create-form .compose-caption .user-box .user-box-picture {
  width: 36px;
  height: 36px;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  border: 1px solid var(--compose-border-color);
}
.app .create .create-form .compose-caption .user-box .user-box-picture img,
.app .create .create-form .compose-caption .user-box .user-box-picture .user-picture {
  width: 100%;
  display: flex;
  aspect-ratio: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -o-object-fit: cover;
     object-fit: cover;
  align-items: center;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  justify-content: center;
  color: var(--compose-secondary-text);
  background-color: var(--compose-secondary-background);
}
.app .create .create-form .compose-caption .user-box .user-box-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .create .create-form .compose-caption .user-box .user-box-data .user-box-name {
  gap: 2px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: flex-start;
  font: normal 14px var(--font-display);
}
.app .create .create-form .compose-caption .user-box .user-box-data .user-box-name .verified-badge {
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .create .create-form .compose-caption .user-box .user-box-data .user-box-username {
  letter-spacing: 0.2px;
  color: var(--compose-secondary-text);
  font: normal 12px var(--font-display);
}
.app .create .create-form form {
  display: flex;
  position: relative;
  flex-direction: column;
}
.app .create .create-form form .wrapper-field {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .create .create-form form .wrapper-field .title {
  padding: 0;
  width: calc(100% - 32px);
  margin: 0 16px;
  border: none;
  cursor: text;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
  font: bold 13.5px/2.5 var(--font-display);
}
.app .create .create-form form .wrapper-field .title:focus-visible {
  outline: none;
}
.app .create .create-form form .wrapper-field .text {
  padding: 0;
  width: calc(100% - 32px);
  margin: 0 16px;
  min-height: 34px;
  border: none;
  resize: none;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  letter-spacing: 0.4px;
  scrollbar-width: none;
  font: 11.5px/1.5 var(--font-display);
}
.app .create .create-form form .wrapper-field .text:focus-visible {
  outline: none;
}
.app .create .create-form form .wrapper-field .counter {
  min-width: 50px;
  height: 34px;
  display: flex;
  margin-left: -8px;
  margin-right: 16px;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: flex-end;
  color: var(--compose-secondary-text);
  transition: var(--compose-transition);
  font: normal 11.5px/1.5 var(--font-display);
  background-color: var(--compose-secondary-background);
}
.app .create .create-form form .wrapper-photos {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--compose-border-color);
  border-bottom: 1px solid var(--compose-border-color);
}
.app .create .create-form form .wrapper-photos .counter {
  top: 16px;
  z-index: 2;
  opacity: 0;
  right: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  transition: var(--compose-transition);
  font: normal 11.5px/1.5 var(--font-mono);
}
.app .create .create-form form .wrapper-photos .icon {
  z-index: 2;
  opacity: 0;
  right: 8px;
  bottom: 8px;
  position: absolute;
  visibility: hidden;
}
.app .create .create-form form .wrapper-photos:hover .icon {
  opacity: 1;
  visibility: visible;
}
.app .create .create-form form .wrapper-photos .photo-item {
  z-index: 1;
  width: 100%;
  display: flex;
  max-height: 620px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .create .create-form form .wrapper-photos .photo-item img {
  z-index: 2;
  max-width: 100%;
  max-height: 620px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  font: normal 11.5px var(--font-display);
}
.app .create .create-form form .wrapper-photos .photo-item .photo-back {
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.app .create .create-form form .wrapper-photos .photo-item .photo-back img {
  width: 100%;
  height: 100%;
  filter: blur(100px);
}
.app .create .create-form form .wrapper-photos .prev-button,
.app .create .create-form form .wrapper-photos .next-button {
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  width: 32px;
  height: 32px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  aspect-ratio: 1;
  transform: translateY(-50%);
  transition: var(--compose-transition);
  z-index: 2;
  top: 50%;
}
.app .create .create-form form .wrapper-photos .prev-button:hover,
.app .create .create-form form .wrapper-photos .next-button:hover {
  transform: translateY(-50%) scale(1.07);
}
.app .create .create-form form .wrapper-photos .prev-button {
  left: 16px;
}
.app .create .create-form form .wrapper-photos .next-button {
  right: 16px;
}
.app .create .create-form form .wrapper-photos:hover .counter {
  opacity: 1;
  visibility: visible;
}
.app .create .create-form form .wrapper-photos:hover .wrapper-photos .counter {
  opacity: 1;
  visibility: visible;
}
.app .create .create-form form .wrapper-photos:hover .prev-button,
.app .create .create-form form .wrapper-photos:hover .next-button {
  opacity: 1;
  visibility: visible;
}
.app .create .create-form form .wrapper-survey {
  gap: 8px;
  width: calc(100% - 32px);
  display: flex;
  padding: 0 16px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app .create .create-form form .wrapper-survey .item {
  width: calc(100% - 28px - 2px);
  height: 34px;
  display: flex;
  padding: 0 14px;
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;
  border: 1px solid var(--compose-border-color);
}
.app .create .create-form form .wrapper-survey .item .text {
  font: normal 12px var(--font-display);
}
.app .create .create-form form .wrapper-survey .item .counter {
  font: normal 12px var(--font-mono);
}
.app .create .create-form form .wrapper-survey .item.active {
  background-color: rgba(62, 165, 255, 0.18);
}
.app .create .create-form form .error {
  color: red;
  padding: 0 16px;
  padding-top: 10px;
  letter-spacing: 0.4px;
  font: normal 11.5px var(--font-display);
}
.app .create .create-form form input,
.app .create .create-form form select {
  width: 100%;
  height: 36px;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  padding: 0px 14px;
  border-radius: 12px;
  letter-spacing: 0.4px;
  background-color: #121212;
  font: normal 1rem var(--font-display);
  border: 1px solid var(--compose-border-color);
}
.app .create .create-form form input:focus-visible,
.app .create .create-form form select:focus-visible {
  outline: none;
}
.app .create .create-form form .selected-images {
  gap: 4px;
  width: 100%;
  cursor: grab;
  height: 280px;
  display: flex;
  overflow: hidden;
  border-radius: 12px;
  justify-content: flex-start;
  transition: var(--compose-transition);
}
.app .create .create-form form .selected-images .image-preview {
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
  border-radius: 12px;
  min-width: -moz-max-content;
  min-width: max-content;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-primary-background);
}
.app .create .create-form form .selected-images .image-preview img {
  z-index: 1;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.app .create .create-form form .selected-images .image-preview video {
  height: 100%;
}
.app .create .create-form form .selected-images .image-preview button {
  top: 8px;
  right: 8px;
  z-index: 2;
  opacity: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  border-radius: 12px;
  transition: var(--compose-transition);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .create .create-form form .selected-images .image-preview:hover button {
  opacity: 1;
  visibility: visible;
}
.app .create .create-form form .selected-images::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.app .create .create-form form .selected-images.grabbing {
  cursor: grabbing;
}
.app .create .create-form form .column {
  gap: 8px;
  padding: 16px 16px 0;
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .create .create-form form .video {
  width: 100% !important;
  border-top: 1px solid var(--compose-border-color);
  border-bottom: 1px solid var(--compose-border-color);
}
.app .create .create-form form .form-action {
  gap: 8px;
  width: calc(100% - 32px);
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
}
.app .create .create-form form .form-action .flex {
  gap: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.app .create .create-form form .form-action .flex button[type=submit] {
  height: 34px;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 12px;
  letter-spacing: 0.4px;
  font: normal 12px var(--font-display);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-border-color);
}
.app .create .create-form form .form-action .flex:first-child {
  justify-content: flex-start;
}
.app .create .create-form form .form-action .flex:last-child {
  justify-content: flex-end;
}
.app .create.show {
  opacity: 1;
  visibility: visible;
}
.app .language {
  inset: 0;
  z-index: 8;
  opacity: 0;
  display: flex;
  position: fixed;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  transition: var(--compose-transition);
  background-color: rgba(0, 0, 0, 0.5);
}
.app .language .close {
  left: 96px;
  top: 16px;
  width: 44px;
  height: 44px;
  position: fixed;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border-color: var(--compose-border-color) !important;
  background-color: var(--compose-secondary-background) !important;
}
.app .language .close:hover {
  transform: scale(1.1);
}
.app .language .grid {
  gap: 10px;
  display: grid;
  padding: 16px;
  border-radius: 16px;
  max-width: var(--compose-wrapper);
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-primary-background);
}
.app .language .grid .label {
  grid-column: span 4;
  font: bold 16px var(--font-display);
}
.app .language .grid button {
  grid-column: span 1;
  margin: 0px;
  height: 30px;
  display: flex;
  cursor: pointer;
  padding: 0px 10px;
  align-items: center;
  border-radius: 8px;
  letter-spacing: 0.4px;
  min-width: -moz-max-content;
  min-width: max-content;
  justify-content: center;
  background-color: transparent;
  font: normal 12px var(--font-display);
  border: 1px solid var(--compose-color-white);
}
.app .language .grid button:disabled {
  cursor: no-drop;
  border-color: var(--compose-border-color);
}
.app .language.show {
  opacity: 1;
  visibility: visible;
}
.app .glass {
  inset: 0px;
  opacity: 0;
  width: 100%;
  z-index: 8;
  height: 100vh;
  border: none;
  display: flex;
  position: fixed;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5019607843);
  transition: var(--compose-transition);
  background-color: var(--compose-primary-background-blur);
}
.app .glass div,
.app .glass img {
  pointer-events: none;
}
.app .glass.show {
  opacity: 1;
  visibility: visible;
}
.app .form {
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .form .block {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.app .form .block label {
  margin-left: 6px;
  margin-bottom: 8px;
  padding: 0 !important;
  font: 500 13.5px var(--font-display) !important;
}
.app .form .block .wrapper-field {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .form .block .wrapper-field input,
.app .form .block .wrapper-field select,
.app .form .block .wrapper-field textarea {
  width: 100%;
}
.app .form .block .wrapper-field #username {
  padding-left: 22px !important;
}
.app .form .block .wrapper-field .at {
  left: 12px;
  color: #a6a4a1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
  font: normal 11.5px var(--font-display);
}
.app .form .block .wrapper-field svg {
  z-index: 2;
  right: 12px;
  left: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
}
.app .form .block input,
.app .form .block select {
  width: calc(100% - 30px);
  height: 36px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 12px;
  padding: 0px 12px !important;
  font: normal 11.5px var(--font-display) !important;
  border: 1px solid var(--compose-border-color);
}
.app .form .block input:disabled,
.app .form .block select:disabled {
  cursor: not-allowed;
}
.app .form .block input:focus-visible,
.app .form .block select:focus-visible {
  outline: none;
}
.app .form .block textarea {
  resize: none;
  width: calc(100% - 30px);
  min-height: 36px;
  padding: 12px 12px;
  border-radius: 12px;
  letter-spacing: 0.4px;
  font: normal 11.5px var(--font-display);
  border: 1px solid var(--compose-border-color);
}
.app .form .block textarea:focus-visible {
  outline: none;
}
.app .form .block .counter {
  right: 0px;
  bottom: 0px;
  position: absolute;
}
.app .form .block button {
  width: 100%;
  height: 36px;
  padding: 0px 24px;
  color: var(--compose-color-white);
  background-color: var(--compose-color-primary);
}
.app .form .block .counter {
  right: 12px;
  bottom: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
  font: normal 11.5px var(--font-mono);
}
.app .form .block.hidden {
  opacity: 0;
  display: none;
  visibility: hidden;
}
.app .form .block.row {
  gap: 12px;
  flex-direction: row;
}
.app .form .wrapper-field {
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .form .wrapper-field .title {
  padding: 0;
  width: calc(100% - 32px);
  margin: 0 16px;
  border: none;
  cursor: text;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
  font: bold 13.5px/2.5 var(--font-display);
}
.app .form .wrapper-field .title:focus-visible {
  outline: none;
}
.app .form .wrapper-field .text {
  padding: 0;
  width: calc(100% - 32px);
  margin: 0 16px;
  min-height: 34px;
  border: none;
  resize: none;
  overflow: hidden;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  letter-spacing: 0.4px;
  scrollbar-width: none;
  font: normal 11.5px var(--font-display);
}
.app .form .wrapper-field .text:focus-visible {
  outline: none;
}
.app .form .wrapper-field .counter {
  min-width: 50px;
  height: 34px;
  display: flex;
  margin-left: -8px;
  margin-right: 16px;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: flex-end;
  color: var(--compose-secondary-text);
  transition: var(--compose-transition);
  font: normal 11.5px var(--font-mono) !important;
  background-color: var(--compose-secondary-background) !important;
}
.app hr {
  width: 100%;
  height: 1px;
  margin: 0px;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  transition: var(--compose-transition);
  background-color: var(--compose-border-color);
}
.app a {
  text-decoration: none;
  color: var(--compose-color-primary);
}
.app a:hover {
  text-decoration: underline;
}
.app .logo {
  font-size: 15px;
  transition: var(--compose-transition);
}
.app .logo:hover {
  transform: scale(1.1);
}
.app .no-link {
  color: inherit;
  text-decoration: none;
}
.app .wrapper {
  display: flex;
  align-items: center;
}
.app .lang {
  width: 30px;
  height: 30px;
  display: flex;
  margin-right: 15px;
  align-items: center;
  text-transform: uppercase;
  justify-content: flex-start;
  font: bold 20px var(--font-urbanist);
}
.app .main .container {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .main .feed {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 120px;
  flex-direction: column;
  justify-content: flex-start;
}
.app .main .feed .feed-header {
  top: 8px;
  gap: 8px;
  z-index: 2;
  width: 100%;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;
  transition: var(--compose-transition);
}
.app .main .feed .feed-body {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.app .main .feed .error {
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  letter-spacing: 0.4px;
  font: normal 11.5px var(--font-display);
}
.app .main .aside {
  width: 352px;
  display: flex;
  min-width: 352px;
  max-width: 352px;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px 0px 75px;
  justify-content: flex-start;
}
.app .watch {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app .watch .watch-grid {
  gap: 30px;
  display: grid;
  padding: 30px 0;
  width: calc(100% - 60px);
  grid-template-columns: repeat(4, 1fr);
}
.app .watch-page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.app .watch-page .watch-page-content {
  width: calc(100% - 60px);
  display: flex;
}
.app .watch-page .watch-page-content .watch-page-content-ppl {
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app .watch-page .watch-page-content .watch-page-content-ppl video {
  width: 1280px;
  height: 720px;
  margin-top: 30px;
  border-radius: 12px;
}
.app .watch-page .watch-page-comments {
  width: calc(100% - 60px);
  height: 400vh;
}
.app .email {
  right: 0;
  bottom: 0;
  width: calc(100% - 80px);
  display: flex;
  position: fixed;
  align-items: flex-end;
  justify-content: center;
  height: calc(100vh - 80px);
  background-color: var(--background-color);
}
.app .email .email-box {
  width: calc(100% - 280px);
  height: calc(100vh - 80px - 30px);
  display: flex;
  border: 1px solid var(--border-color);
}
.app .email .email-box .header-chat {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--border-color);
}
.app .email .email-box .chats-list {
  width: 400px;
  height: 100%;
  visibility: hidden;
  visibility: visible;
  border-right: 1px solid var(--border-color);
}
.app .email .email-box .chat-box {
  width: calc(100% - 400px);
  height: 100%;
}
.app .post {
  z-index: 1;
  width: 100%;
  width: calc(100% - 2px);
  display: flex;
  max-width: 620px;
  position: relative;
  border-radius: 16px;
  flex-direction: column;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .post a {
  color: inherit;
  text-decoration: none;
}
.app .post .post-menu {
  right: 0;
  top: 100%;
  top: 16px;
  z-index: 8;
  opacity: 0;
  padding: 6px;
  display: flex;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  border-radius: 16px;
  flex-direction: column;
  min-width: -moz-fit-content;
  min-width: fit-content;
  transition: var(--compose-transition);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .post .post-menu hr {
  width: 100%;
  height: 1px;
  border: none;
  margin: 6px 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  background-color: var(--compose-border-color);
}
.app .post .post-menu .wrapper {
  flex-direction: row !important;
}
.app .post .post-menu .post-menu-item {
  width: 100%;
  height: 38px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  padding-right: 16px;
  justify-content: center;
  transition: var(--compose-transition);
  background-color: var(--compose-secondary-background);
}
.app .post .post-menu .post-menu-item .wrapper {
  gap: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px 6px;
  align-items: center;
  justify-content: flex-start;
}
.app .post .post-menu .post-menu-item .wrapper .icon {
  display: flex;
  font-size: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  pointer-events: none;
  justify-content: flex-start;
}
.app .post .post-menu .post-menu-item .wrapper .text {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  letter-spacing: 0.2px;
  min-width: -moz-max-content;
  min-width: max-content;
  font: normal 12px var(--font-display);
}
.app .post .post-menu .post-menu-item:hover {
  background-color: var(--compose-hovered-background);
}
.app .post .post-menu.show {
  opacity: 1;
  visibility: visible;
}
.app .post .post-head {
  gap: 8px;
  z-index: 4;
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: var(--compose-secondary-background);
}
.app .post .post-head .icon-button {
  z-index: 1;
  width: 36px;
  height: 36px;
  display: flex;
  min-width: 36px;
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  transition: var(--compose-transition);
}
.app .post .post-head .icon-button:hover {
  background-color: rgb(30, 30, 30);
  border-color: var(--compose-border-color);
}
.app .post .post-content {
  z-index: 2;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background-color: var(--compose-secondary-background);
}
.app .post .post-content .title {
  margin: 0 16px;
  color: var(--compose-primary-text);
  font: bold 13.5px/2 var(--font-display);
}
.app .post .post-content .text {
  margin: 0px 16px;
  letter-spacing: 0.4px;
  white-space: pre-wrap;
  color: var(--compose-primary-text);
  font: 300 11.5px/1.75 var(--font-display);
}
.app .post .post-content .text a {
  text-decoration: none;
  color: var(--compose-color-primary);
}
.app .post .post-content .text a:hover {
  text-decoration: underline;
}
.app .post .post-content .space {
  width: 100%;
  height: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
.app .post .post-content .wrapper-photos {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--compose-border-color);
  border-bottom: 1px solid var(--compose-border-color);
}
.app .post .post-content .wrapper-photos .counter {
  top: 16px;
  z-index: 2;
  opacity: 0;
  right: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  font: normal 12px var(--font-mono);
  transition: var(--compose-transition);
}
.app .post .post-content .wrapper-photos .photo-item {
  z-index: 1;
  width: 100%;
  display: flex;
  max-height: 620px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .post .post-content .wrapper-photos .photo-item img {
  z-index: 2;
  max-width: 100%;
  max-height: 620px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  font: normal 11.5px var(--font-display);
}
.app .post .post-content .wrapper-photos .photo-item .photo-back {
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #121212;
}
.app .post .post-content .wrapper-photos .photo-item .photo-back img {
  width: 100%;
  height: 100%;
  filter: blur(100px);
}
.app .post .post-content .wrapper-photos .prev-button,
.app .post .post-content .wrapper-photos .next-button {
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  width: 32px;
  height: 32px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  aspect-ratio: 1;
  transform: translateY(-50%);
  transition: var(--compose-transition);
  z-index: 2;
  top: 50%;
}
.app .post .post-content .wrapper-photos .prev-button:hover,
.app .post .post-content .wrapper-photos .next-button:hover {
  transform: translateY(-50%) scale(1.07);
}
.app .post .post-content .wrapper-photos .prev-button {
  left: 16px;
}
.app .post .post-content .wrapper-photos .next-button {
  right: 16px;
}
.app .post .post-content .wrapper-photos:hover .counter {
  opacity: 1;
  visibility: visible;
}
.app .post .post-content .wrapper-photos:hover .wrapper-photos .counter {
  opacity: 1;
  visibility: visible;
}
.app .post .post-content .wrapper-photos:hover .prev-button,
.app .post .post-content .wrapper-photos:hover .next-button {
  opacity: 1;
  visibility: visible;
}
.app .post .post-content .wrapper-video {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--compose-border-color);
  border-bottom: 1px solid var(--compose-border-color);
}
.app .post .post-content .wrapper-video .video {
  cursor: pointer;
  width: 100% !important;
  height: 350px !important;
  background-color: #121212;
}
.app .post .post-content .wrapper-audio {
  width: 100%;
  display: flex;
  aspect-ratio: 1;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--compose-border-color);
  border-bottom: 1px solid var(--compose-border-color);
}
.app .post .post-content .wrapper-audio .cover {
  inset: 0;
  z-index: 1;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.app .post .post-content .wrapper-audio .back {
  inset: 0;
  z-index: 2;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  transition: var(--compose-transition);
  background-color: rgba(0, 0, 0, 0.5);
}
.app .post .post-content .wrapper-audio .time {
  top: 16px;
  z-index: 3;
  opacity: 0;
  right: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  font: normal 12px var(--font-mono);
  transition: var(--compose-transition);
}
.app .post .post-content .wrapper-audio .play {
  z-index: 3;
  opacity: 0;
  width: 44px;
  height: 44px;
  border: none;
  display: flex;
  cursor: pointer;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: var(--compose-transition);
}
.app .post .post-content .wrapper-audio .bar {
  bottom: 0;
  z-index: 3;
  opacity: 0;
  width: 100%;
  display: flex;
  padding: 26px 0px;
  position: absolute;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: var(--compose-transition);
  background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.4));
}
.app .post .post-content .wrapper-audio:hover .back,
.app .post .post-content .wrapper-audio:hover .time,
.app .post .post-content .wrapper-audio:hover .play,
.app .post .post-content .wrapper-audio:hover .bar {
  opacity: 1;
  visibility: visible;
}
.app .post .post-content .repost {
  margin: 0 16px;
  padding: 16px 0;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid var(--compose-border-color);
}
.app .post .post-content .repost .copyrigth {
  gap: 4px;
  display: flex;
  margin: 0 16px;
  padding: 16px 0 0;
  align-items: center;
  justify-content: flex-start;
  font: normal 12.5px var(--font-display);
}
.app .post .post-content .repost .copyrigth .of {
  color: var(--compose-secondary-text);
}
.app .post .post-content .repost .copyrigth a,
.app .post .post-content .repost .copyrigth .link {
  gap: 2px;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  color: var(--compose-primary-text);
}
.app .post .post-content .wrapper-link {
  padding: 0 16px;
  width: calc(100% - 32px - 2px);
}
.app .post .post-content .wrapper-link .card-link {
  width: 100%;
  width: calc(100% - 2px);
  display: flex;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--compose-border-color);
  background-color: #121212;
  text-decoration: none;
  color: inherit;
}
.app .post .post-content .wrapper-link .card-link .card-link-image {
  width: 100%;
  max-height: 479px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  border-bottom: 1px solid var(--compose-border-color);
}
.app .post .post-content .wrapper-link .card-link .card-link-image img {
  width: 100%;
  border: none;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  box-sizing: border-box;
}
.app .post .post-content .wrapper-link .card-link .card-link-content {
  gap: 8px;
  width: calc(100% - 32px);
  display: flex;
  padding: 16px;
  flex-direction: column;
}
.app .post .post-content .wrapper-link .card-link .card-link-content .link {
  color: var(--compose-secondary-text);
  font: 300 12px var(--font-display);
}
.app .post .post-content .wrapper-link .card-link .card-link-content .title {
  margin: 0;
  color: var(--compose-primary-text);
  font: bold 15px var(--font-display) !important;
}
.app .post .post-content .wrapper-link .card-link .card-link-content .description {
  overflow: hidden;
  display: -webkit-box;
  letter-spacing: 0.4px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font: 300 12px var(--font-display);
}
.app .post .post-content .wrapper-link .card {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--compose-border-color);
  background-color: #121212;
  text-decoration: none;
  color: inherit;
}
.app .post .post-content .wrapper-link .card .card-image {
  width: 120px;
  height: 120px;
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  background-color: #121212;
}
.app .post .post-content .wrapper-link .card .card-image img {
  width: 100%;
  height: 100%;
  border: none;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.app .post .post-content .wrapper-link .card .card-content {
  gap: 8px;
  width: calc(100% - 32px);
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.app .post .post-content .wrapper-link .card .card-content .link {
  letter-spacing: 0.4px;
  color: var(--compose-secondary-text);
  font: 300 12px var(--font-display);
}
.app .post .post-content .wrapper-link .card .card-content .title {
  margin: 0;
  letter-spacing: 0.4px;
  font: bold 15px var(--font-display) !important;
}
.app .post .post-content .wrapper-link .card .card-content .description {
  overflow: hidden;
  display: -webkit-box;
  letter-spacing: 0.4px;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font: 300 12px var(--font-display);
}
.app .post .post-action {
  z-index: 3;
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  padding: 8px 0;
  padding: 12px 0;
  align-items: center;
  background-color: var(--compose-secondary-background);
}
.app .post .post-action .flex {
  gap: 6px;
  width: 100%;
  display: flex;
  align-items: center;
}
.app .post .post-action .flex:first-child {
  justify-content: flex-start;
}
.app .post .post-action .flex:last-child {
  justify-content: flex-end;
}
.app .post .post-action .flex:last-child .reaction-area {
  justify-content: flex-end;
}
.app .post .post-form {
  z-index: 1;
  display: none;
  margin: 0 16px;
  padding: 16px 0;
  flex-direction: column;
  transition: var(--compose-transition);
  border-top: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .post .post-form form {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}
.app .post .post-form form a {
  height: 34px;
  display: flex;
  color: inherit;
  min-width: 34px;
  overflow: hidden;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  color: var(--compose-secondary-text);
  transition: var(--compose-transition);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .post .post-form form a img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.app .post .post-form form a:hover {
  transform: scale(1.06) rotate(-16deg);
}
.app .post .post-form form .wrapper-field {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
}
.app .post .post-form form .wrapper-field input {
  z-index: 1;
  padding-right: 40px;
}
.app .post .post-form form .wrapper-field .counter {
  z-index: 2;
  right: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
  letter-spacing: 0.4px;
  color: var(--compose-secondary-text);
  font: normal 11.5px var(--font-display);
}
.app .post .post-form form input {
  width: 100%;
  border: none;
  height: 34px;
  padding: 0 14px;
  background: none;
  border-radius: 12px;
  letter-spacing: 0.4px;
  height: 32px;
  background-color: #121212;
  font: normal 11.5px var(--font-display);
  border: 1px solid var(--compose-border-color);
}
.app .post .post-form form input:focus-visible {
  outline: none;
}
.app .post .post-form form button {
  height: 34px;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 0px 14px;
  border-radius: 12px;
  letter-spacing: 0.4px;
  transition: var(--compose-transition);
  font: normal 11.5px var(--font-display);
  background-color: var(--compose-border-color);
  border: 1px solid var(--compose-border-color);
}
.app .post .post-comment-input {
  gap: 8px;
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--compose-border-color);
}
.app .post .post-comment-input .wrapper-field {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .post .post-comment-input .wrapper-field input {
  width: 100%;
  height: 34px;
  border: none;
  padding: 0 14px;
  padding-right: 40px;
  border-radius: 10px;
  letter-spacing: 0.4px;
  background-color: #121212;
  font: normal 11.5px var(--font-display);
  border: 1px solid var(--compose-border-color);
}
.app .post .post-comment-input .wrapper-field input:focus-visible {
  outline: none;
}
.app .post .post-comment-input .wrapper-field .counter {
  right: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: absolute;
  pointer-events: none;
  color: var(--compose-secondary-text);
  font: normal 11.5px var(--font-mono);
}
.app .post .post-comments {
  gap: 10px;
  z-index: 1;
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  letter-spacing: 0.4px;
  flex-direction: column;
  font: normal 11.5px var(--font-display);
  border-top: 1px solid var(--compose-border-color);
}
.app .post .post-comments .comment {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.app .post .post-comments .comment .comment-wrapper {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.app .post .post-comments .comment .comment-user {
  width: 36px;
  display: flex;
  min-width: 36px;
  align-items: flex-start;
  justify-content: center;
}
.app .post .post-comments .comment .comment-text {
  gap: 8px;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--compose-secondary-text);
}
.app .post .post-comments .comment .comment-text .info {
  gap: 2px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.app .post .post-comments .comment .comment-text .info .name {
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--compose-primary-text);
  font: bold 13.5px var(--font-display);
}
.app .post .post-comments .comment .comment-text .info .name .user-link {
  gap: 2px;
  display: flex;
  align-items: center;
}
.app .post .post-comments .comment .comment-text .info .name .author {
  gap: 1px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: var(--compose-color-blue);
  font: normal 11.5px var(--font-display);
}
.app .post .post-comments .comment .comment-text .info .name .pin {
  display: flex;
  cursor: default;
  color: #cdcdcd;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  letter-spacing: 0.4px;
  transition: var(--compose-transition);
  font: normal 10.5px var(--font-display);
  background-color: rgba(241, 242, 240, 0.1882352941);
  border-radius: 4px;
  padding: 2px 1px;
  padding-right: 4px;
}
.app .post .post-comments .comment .comment-text .info .username {
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--compose-secondary-text);
  font: normal 12px var(--font-display);
}
.app .post .post-comments .comment .comment-text .text {
  width: 100%;
  height: 100%;
  letter-spacing: 0.4px;
  white-space: pre-wrap;
  color: var(--compose-primary-text);
  font: normal 11.5px/1.75 var(--font-display);
}
.app .post .post-comments .comment .comment-text .action {
  gap: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.app .post .post-comments .comment .comment-text .action .loved {
  width: 36px;
  height: 36px;
  display: none;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .post .post-comments .comment .comment-text .action .loved img {
  z-index: 1;
  position: absolute;
  border-radius: 8px;
}
.app .post .post-comments .comment .comment-text .action .loved svg {
  right: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
  color: var(--compose-color-red);
}
.app .post .post-comments .comment .comment-action {
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.app .post .post-comments .comment .comment-action .icon {
  border-color: transparent;
}
.app .post .post-comments .comment .comment-action .post-menu {
  top: 0;
}
.app .post .post-comments .comments-empty {
  gap: 12px;
  width: 100%;
  display: flex;
  min-height: 180px;
  align-items: center;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.app .post .post-comments .comments-empty .title {
  font: bold 20px var(--font-display);
}
.app .post .post-comments .comments-empty .subtitle {
  font: normal 13px var(--font-display);
}
.app .post .post-posted {
  display: flex;
  margin: 0 16px;
  padding: 12px 0;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: space-between;
  font: normal 11.5px var(--font-mono);
  color: var(--compose-secondary-text);
  border-bottom: 1px solid var(--compose-border-color);
}
.app .post .error {
  gap: 20px;
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  align-items: center;
  letter-spacing: 0.4px;
  flex-direction: column;
  justify-content: center;
  font: normal 13px var(--font-display);
}
.app .article {
  padding: 10px 15px;
  border-radius: 16px;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .article h1 {
  margin: 0px;
  font: bold 18px/2 var(--font-display);
}
.app .article small {
  color: var(--color-inactive);
  font: normal 10px/1.5 var(--font-display);
}
.app .article p {
  display: flex;
  text-wrap: balance;
  margin-bottom: 0px;
  font: normal 12px/2 var(--font-display);
}
.app .article .article-action {
  gap: 8px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  align-items: center;
  border-top: 1px solid var(--compose-border-color);
}
.app .article .article-action form {
  gap: 6px;
  width: 50%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: flex-start;
}
.app .article .article-action form input {
  width: 100%;
  height: 30px;
  padding: 0px 12px;
  border-radius: 8px;
  letter-spacing: 0.4px;
  font: normal 12px var(--font-display);
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-primary-background);
}
.app .article .article-action form button[type=submit] {
  height: 30px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font: normal 12px var(--font-display);
  background-color: var(--primary-color);
}
.app .article .article-action .button.icon {
  width: 32px;
  height: 32px;
  font-size: 12px;
  border-radius: 8px;
}
.app .article .article-action .button.icon:hover {
  background-color: var(--compose-primary-background);
}
.app .sidebar {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  flex-direction: column;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .sidebar .sidebar-title {
  display: flex;
  margin: 0 16px;
  padding: 16px 0;
  align-items: center;
  justify-content: flex-start;
  font: bold 18px var(--font-display);
}
.app .sidebar .sidebar-content {
  display: flex;
  margin: 0 16px;
}
.app .sidebar .sidebar-content .moments {
  gap: 2px;
  width: 100%;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  border-radius: 12px;
  flex-direction: row;
  scrollbar-width: none;
}
.app .sidebar .sidebar-content .moments .moment-item {
  height: 200px;
  cursor: pointer;
  min-width: 120px;
  overflow: hidden;
  border-radius: 12px;
  background-color: var(--compose-primary-background);
}
.app .sidebar .sidebar-content .trends {
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .sidebar .sidebar-content .trends .trend-card {
  gap: 12px;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-link {
  width: 100%;
  color: inherit;
  text-decoration: none;
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details {
  gap: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details svg {
  pointer-events: none;
  color: var(--compose-color-white);
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details #tag {
  margin-left: 4px;
  border-radius: 4px;
  position: relative;
  transform: rotate(45deg);
  color: var(--color-white);
  background-color: var(--primary-color);
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details #tag::before {
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  content: "tag";
  position: absolute;
  border-radius: 4px;
  transform: rotate(-45deg);
  background-color: var(--primary-color);
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details .trend-card-wrapper {
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details .trend-card-wrapper .trend-card-title {
  color: var(--compose-primary-text);
  font: bold 16px var(--font-display);
}
.app .sidebar .sidebar-content .trends .trend-card .trend-card-details .trend-card-wrapper .trend-card-subtitle {
  color: var(--compose-secondary-text);
  font: normal 12px var(--font-display);
}
.app .sidebar .sidebar-content .popular {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .sidebar .sidebar-content .about {
  gap: 12px;
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}
.app .sidebar .sidebar-content .about .about-wrap {
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
}
.app .sidebar .sidebar-content .about .about-wrap .about-link {
  letter-spacing: 0.2px;
  text-decoration: none;
  color: var(--compose-secondary-text);
  font: normal 12px var(--font-display);
}
.app .sidebar .sidebar-content .about .about-wrap .about-link:hover {
  text-decoration: underline;
}
.app .sidebar .sidebar-content .about .about-copy {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app .sidebar .sidebar-content .about .about-copy span {
  letter-spacing: 0.2px;
  color: var(--compose-secondary-text);
  font: normal 12.5px var(--font-display);
}
.app .sidebar .sidebar-content .about .about-copy span:last-child {
  cursor: help;
}
.app .avatar-box {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  background-color: #121212;
  transition: var(--compose-transition);
  border: 1px solid var(--compose-border-color);
}
.app .avatar-box .avatar {
  border: none;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.app .avatar-box:hover {
  transform: scale(1.06) rotate(-16deg);
}
.app .user-card {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app .user-card .user-info {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.app .user-card .user-info a {
  color: inherit;
  text-decoration: none;
}
.app .user-card .user-info a:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.app .user-card .user-info .user-picture {
  width: 36px;
  height: 36px;
  display: flex;
  aspect-ratio: 1;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
  align-items: center;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  justify-content: center;
  color: var(--compose-secondary-text);
  transition: var(--compose-transition);
  background-color: var(--compose-primary-background);
}
.app .user-card .user-info a:hover .user-picture {
  transform: scale(1.06) rotate(-16deg);
}
.app .user-card .user-info .user-data {
  gap: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .user-card .user-info .user-data .user-details {
  gap: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.app .user-card .user-info .user-data .user-details .name {
  width: -moz-fit-content;
  width: fit-content;
  height: 17px;
  overflow: hidden;
  max-width: 200px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  color: var(--compose-primary-text);
  font: bold 14px var(--font-display);
}
.app .user-card .user-info .user-data .user-details .verified {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}
.app .user-card .user-info .user-data .user-details .verified svg {
  width: 100%;
  height: 100%;
}
.app .user-card .user-info .user-data .user-details .username,
.app .user-card .user-info .user-data .user-details .posted {
  margin: 0;
  padding: 0;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0.4px;
  min-width: -moz-max-content;
  min-width: max-content;
  color: var(--compose-secondary-text);
  font: normal 12px var(--font-display);
}
.app .watch-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: goldenrod;
}
.app .watch-card .watch-card-video {
  display: flex;
  position: relative;
  align-items: center;
}
.app .watch-card .watch-card-video a {
  display: flex;
  border-radius: 12px;
  background-color: rebeccapurple;
}
.app .watch-card .watch-card-video video {
  width: 100%;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: 12px;
}
.app .watch-card .watch-card-details .watch-card-details-title {
  margin: 10px 0 14px;
  line-height: 1.5 !important;
  font: bold 14px var(--font-urbanist);
}
.app .watch-card .watch-card-details .watch-card-details-user {
  border-radius: 12px;
}
.app .notification {
  width: 100%;
  height: 85px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--compose-border-color);
}
.app .notification .notification-link {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  color: inherit;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
}
.app .notification .notification-wrapper {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 16px 0;
  align-items: center;
  justify-content: flex-start;
}
.app .notification .notification-wrapper .picture-box {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .notification .notification-wrapper .picture-box .picture {
  width: 36;
  height: 36;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 12px;
  pointer-events: none;
  transition: var(--compose-transition);
}
.app .notification .notification-wrapper .picture-box:hover .picture {
  transform: scale(1.06) rotate(-16deg);
}
.app .notification .notification-wrapper .notification-content {
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app .notification .notification-wrapper .notification-content .title {
  font: normal 14px var(--font-display);
}
.app .notification .notification-wrapper .notification-content .time {
  font: normal 12px var(--font-display);
}
.app .notification .notification-photo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app .notification .notification-photo img {
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 14px;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  border: 1px solid var(--compose-border-color);
}
.app .notification:first-child {
  border-top: 1px solid var(--compose-border-color);
}
.app .notify {
  background-color: rosybrown;
}
.app table {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app table th,
.app table td {
  height: 30px;
  padding: 0 12px;
  text-align: left;
  letter-spacing: 0.4px;
  border-right: 1px solid var(--compose-border-color);
}
.app table th:last-child,
.app table td:last-child {
  border-right: none;
}
.app table th {
  color: var(--compose-primary-text);
  font: bold 12.5px var(--font-mono);
  border-bottom: 1px solid var(--compose-border-color);
}
.app table td {
  color: var(--compose-secondary-text);
  font: normal 11.5px var(--font-mono);
  transition: var(--compose-transition);
}
.app table tr:hover td {
  color: var(--compose-primary-text);
}
.app .popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  display: flex;
  position: absolute;
  visibility: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--compose-primary-background-blur);
}
.app .popup .profile-menu {
  padding: 6px;
  width: 310px;
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  flex-direction: column;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.app .popup .profile-menu hr {
  margin: 6px 0px;
}
.app .popup .profile-menu .profile-menu-item {
  width: 100%;
  height: 48px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 12px;
  padding-right: 16px;
  justify-content: center;
  transition: var(--compose-transition);
  background-color: var(--compose-secondary-background);
}
.app .popup .profile-menu .profile-menu-item .wrapper {
  gap: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0px 6px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.app .popup .profile-menu .profile-menu-item .wrapper .icon {
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}
.app .popup .profile-menu .profile-menu-item .wrapper .text {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  letter-spacing: 0.4px;
  min-width: -moz-max-content;
  min-width: max-content;
  font: normal 14px var(--font-display);
}
.app .popup .profile-menu .profile-menu-item:hover {
  background-color: var(--compose-hovered-background);
}
.app .popup .profile-menu .profile-menu-item:disabled {
  cursor: no-drop;
}
.app .popup.show {
  opacity: 1;
  visibility: visible;
}

.app.no-scroll {
  position: fixed;
}

.login-page,
.register-page {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--compose-primary-background);
}
.login-page .form-box,
.register-page .form-box {
  width: 300px;
  padding: 16px;
  margin-top: 200px;
  border-radius: 16px;
  border: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.login-page .form-box .form,
.register-page .form-box .form {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login-page .form-box .form .form-group,
.register-page .form-box .form .form-group {
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.login-page .form-box .form .form-group input,
.login-page .form-box .form .form-group select,
.register-page .form-box .form .form-group input,
.register-page .form-box .form .form-group select {
  width: 100%;
  height: 40px;
  border: none;
  letter-spacing: 0.4px;
  font: normal 12px var(--font-display);
  border-bottom: 1px solid var(--compose-border-color);
  background-color: var(--compose-secondary-background);
}
.login-page .form-box .form .form-group input:focus-visible,
.login-page .form-box .form .form-group select:focus-visible,
.register-page .form-box .form .form-group input:focus-visible,
.register-page .form-box .form .form-group select:focus-visible {
  outline: none;
}
.login-page .form-box .form .form-group select,
.register-page .form-box .form .form-group select {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.login-page .form-box .form .form-group button,
.register-page .form-box .form .form-group button {
  width: 100%;
  height: 40px;
  color: white;
  cursor: pointer;
  margin: 20px 0px;
  border-radius: 12px;
  letter-spacing: 0.4px;
  background-color: black;
  border: 1px solid black;
  font: normal 13.5px var(--font-display);
}
.login-page .form-box .form .form-group a,
.register-page .form-box .form .form-group a {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  letter-spacing: 0.4px;
  text-decoration: none;
  justify-content: center;
  color: rgb(39, 130, 227);
  font: normal 12px var(--font-display);
}
.login-page .form-box .form .form-group a:hover,
.register-page .form-box .form .form-group a:hover {
  text-decoration: underline;
}
.login-page .form-box .form .form-group svg,
.register-page .form-box .form .form-group svg {
  right: 2px;
  position: absolute;
  pointer-events: none;
}
.login-page .form-box .form .form-group span,
.register-page .form-box .form .form-group span {
  width: 100%;
  height: 24px;
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: firebrick;
  align-items: center;
  letter-spacing: 0.2px;
  font: normal 10.5px/2 var(--font-display);
}

.comps {
  width: 100%;
  display: flex;
  overflow-y: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.comps .title-i {
  margin: 40px 0px;
  font: bold 24px var(--font-display);
}
.comps .title-ii {
  margin: 18px 0px;
  font: bold 20px var(--font-display);
}
.comps hr {
  margin: 0px;
  height: 1px;
  width: 620px;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 16px;
  pointer-events: none;
  background-color: var(--compose-border-color);
}
.comps .column {
  gap: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 230px;
}

@media screen and (max-width: 1242px) {
  .app .main .container {
    width: calc(100% - 40px);
  }
  .app .main .container .profile .profile-header {
    width: 100%;
  }
}
@media screen and (max-width: 64em) {
  .menu {
    display: none !important;
  }
  .menu-mobile {
    display: flex !important;
  }
  .main {
    width: 100% !important;
  }
  .main .container {
    width: 100% !important;
  }
  .main .container {
    margin: 0px 30px;
    justify-content: center !important;
  }
  .main .container .feed {
    width: calc(100% - 50px) !important;
  }
  .main .container .aside {
    display: none !important;
  }
}
@media screen and (max-width: 40em) {
  .menu {
    bottom: 0;
    width: 100%;
    top: initial;
    border-right: none;
    margin-bottom: -1px;
    height: 82px;
    border-top: 1px solid var(--compose-border-color);
    background-color: var(--compose-secondary-background);
  }
  .menu .wrapper {
    gap: 0;
    height: initial;
  }
  .menu .wrapper .flex .logo {
    display: none;
  }
  .menu .wrapper .flex .nav-menu .nav-list {
    gap: 8px;
    flex-direction: row;
    justify-content: center;
  }
  .menu .wrapper .flex .nav-menu .nav-list .nav-title {
    display: none;
  }
  .menu .wrapper .flex:last-child .nav-menu {
    display: none;
  }
  .main .container .aside {
    display: none !important;
  }
  .main .container .post {
    max-width: 100%;
    min-width: 100%;
  }
  .main .container .post .post-head .user-card .follow-button,
  .main .container .post .post-head .user-card .followed-button {
    max-width: 80px !important;
    display: none !important;
  }
  .main .feed {
    margin: 0 !important;
  }
  .post .post-head .user-card .follow,
  .post .post-head .user-card .followed {
    display: none !important;
  }
}/*# sourceMappingURL=app.css.map */